import produce from 'immer';
import { create, SetState } from 'zustand';

import { PARAMS_KEYS } from '../hooks/useSearchParams';
import { StudyFilterFields, StudyFilterName } from '../types/Filter';
import { UserPreference, UserPreferenceFamily } from '../types/UserPreference';
import { OrganizationSpecificFilterAPIKeys } from '../constants/organizationSpecificFields';

export const immer = (config) => (set, get) => config((fn) => set(produce(fn)), get);

export interface FilterStoreState {
  searchBy: string | null;
  filters: Array<UserPreference>;
  temporalSearchBy: string | null;
  filterFields: Partial<StudyFilterFields>;
  temporalFilterFields: Partial<StudyFilterFields>;
  organizationSpecificFilterFields: Partial<StudyFilterFields>;
  setSearchBy: (searchBy: string | null) => void;
  setTemporalSearchBy: (searchBy: string | null) => void;
  setFilters: (filters: Array<UserPreference>) => void;
  deleteFilter: (filterKey: string) => void;
  setTemporalFilterFields: (temporalFilterFields: Partial<StudyFilterFields>) => void;
  deleteTemporalFilterField: (filterKey: string) => void;
  deleteAllTemporalFilterFields: () => void;
}

const parseFilters = (filters: Array<UserPreference>, families: Array<UserPreferenceFamily>) =>
  filters
    .filter((preference) => families.includes(preference.family))
    .map((filter) => {
      try {
        return { ...filter, value: JSON.parse(filter.value) };
      } catch (error) {
        console.error('Error parsing filter value', error);
        return { ...filter, value: null };
      }
    });

const filterStore = (set: SetState<FilterStoreState>): FilterStoreState => {
  const searchBy = new URLSearchParams(window.location.search).get(PARAMS_KEYS.SEARCH) || null;
  return {
    searchBy,
    filters: [],
    filterFields: {},
    temporalSearchBy: null,
    temporalFilterFields: {},
    organizationSpecificFilterFields: {},
    setSearchBy: (searchBy: string | null) =>
      set((state) => {
        state.searchBy = searchBy;
        return state;
      }),
    setTemporalSearchBy: (searchBy: string | null) =>
      set((state) => {
        state.temporalSearchBy = searchBy;
        return state;
      }),
    setFilters: (filters: Array<UserPreference>) =>
      set((state) => {
        const parsedFilters = parseFilters(filters, [
          UserPreferenceFamily.FILTER,
          UserPreferenceFamily.CUSTOM_LIST_FILTER,
        ]);
        const { organizationSpecificFields, ...parsedFilterFields } = Object.fromEntries(
          parsedFilters
            .filter((filter) => filter.key !== StudyFilterName.search)
            .map((filter) => [filter.key, filter.value]),
        );

        state.filters = parsedFilters;
        state.filterFields = parsedFilterFields;
        state.organizationSpecificFilterFields =
          Object.keys(organizationSpecificFields ?? {}).length > 0
            ? {
                [StudyFilterName.rightPossession]:
                  organizationSpecificFields?.[OrganizationSpecificFilterAPIKeys.rightPossession],
              }
            : {};
        return state;
      }),
    deleteFilter: (filterKey: string) =>
      set((state) => {
        if (!filterKey.trim()) return state;
        state.filters = state.filters.filter((filter) => filter.key !== filterKey);
        delete state.filterFields[filterKey];
        delete state.organizationSpecificFilterFields[filterKey];
        return state;
      }),
    setTemporalFilterFields: (temporalFilterFields: Partial<StudyFilterFields>) =>
      set((state) => {
        if (!temporalFilterFields) return state;
        state.temporalFilterFields = temporalFilterFields;
        return state;
      }),
    deleteTemporalFilterField: (filterKey: string) =>
      set((state) => {
        if (!filterKey.trim()) return state;
        delete state.temporalFilterFields[filterKey];
        return state;
      }),
    deleteAllTemporalFilterFields: () =>
      set((state) => {
        state.temporalFilterFields = {};
        return state;
      }),
  };
};

export const useFilterStore = create<FilterStoreState>(immer(filterStore));
