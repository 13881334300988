/**
 * Enumerates the different families of user preferences.
 */
export enum UserPreferenceFamily {
  FILTER = 'FILTER',
  SCREENS = 'SCREENS',
  LIST_TYPE = 'LIST_TYPE',
  SHORTCUTS = 'SHORTCUTS',
  STUDY_LIST = 'STUDY_LIST',
  FILTER_ORDERS = 'FILTER_ORDERS',
  CUSTOM_LIST_FILTER = 'CUSTOM_LIST_FILTER',
  FILTER_APPOINTMENTS = 'FILTER_APPOINTMENTS',
  ORDERING = 'ORDERING',
  MODALS = 'MODALS',
}

/**
 * Represents a user preference configuration.
 */
export interface UserPreference {
  value: string;
  id: string;
  key: string;
  createdAt: Date;
  updatedAt: Date;
  family: UserPreferenceFamily;
  customStudyList: { id: string } | null;
}
