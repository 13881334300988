import { STUDY_ORGANIZATION_SPECIFIC_FILTER_KEYS } from '~/constants';

import { UserPreference } from '../types/UserPreference';
import { StudyFilterFields, StudyFilterName } from '../types/Filter';

type NonEmptyKeys<T extends Record<string, unknown>> = Array<keyof T>;

type NonEmptyObject<T> = {
  [K in keyof T]: NonNullable<T[K]>;
};

export const isEmpty = (val: unknown): boolean => {
  if (!val) return true;
  if (typeof val === 'string') return val === '';
  if (Array.isArray(val)) return val.length === 0;
  if (typeof val === 'object') return Object.keys(val as Record<string, unknown>).length === 0;
  if (val instanceof Map || val instanceof Set) return val.size === 0;
  return false;
};

export const isNotEmpty = (value: any, visited = new WeakSet()): boolean => {
  if (value === null || value === undefined) return false;
  if (typeof value === 'string' && value.trim() === '') return false;
  if (typeof value === 'number' && Number.isNaN(value)) return false;
  if (typeof value === 'object') {
    if (visited.has(value)) return false;
    visited.add(value);
    if (Array.isArray(value)) return value.some((item) => isNotEmpty(item, visited));
    return Object.keys(value).some((key) => isNotEmpty(value[key], visited));
  }

  return true;
};

export const getNonEmptyKeys = <T extends Partial<StudyFilterFields>>(obj: T): NonEmptyKeys<T> => {
  return Object.keys(obj).filter((key) => isNotEmpty(obj[key])) as NonEmptyKeys<T>;
};

export const filterNonEmptyValues = <T extends Partial<StudyFilterFields>>(obj: T): NonEmptyObject<T> => {
  try {
    return Object.keys(obj).reduce((acc, key) => {
      if (isNotEmpty(obj[key])) {
        acc[key] = obj[key];
      }
      return acc;
    }, {} as NonEmptyObject<T>);
  } catch (error) {
    console.error('Error filtering non-empty values', error);
    return {} as NonEmptyObject<T>;
  }
};

export const getEmptyKeysForRemoval = <T extends Record<string, any>>(originalObj: T, newObj: T): Array<keyof T> => {
  if (!originalObj || !newObj) return [];

  const emptyKeysForRemoval: Array<keyof T> = [];

  Object.keys(originalObj).forEach((key) => {
    const originalValue = originalObj[key as keyof T];
    const newValue = newObj[key as keyof T];

    if (isNotEmpty(originalValue) && !isNotEmpty(newValue)) {
      emptyKeysForRemoval.push(key as keyof T);
    }
  });

  return emptyKeysForRemoval;
};

export const getFilterByKey = (key: string, filters: Array<UserPreference>) => {
  if (!key.trim() || !Array.isArray(filters)) return undefined;
  return filters.find((filter) => filter.key === key);
};

export const getFiltersKeysAndValues = (
  fields: Partial<StudyFilterFields>,
  filterFields: Partial<StudyFilterFields>,
  organizationSpecificFilterFields?: Partial<StudyFilterFields>,
) => {
  const filterList = getNonEmptyKeys(fields);
  const filterRemoveList = getEmptyKeysForRemoval(filterFields, fields);
  const filterRemoveListOrganizationSpecific = getEmptyKeysForRemoval(organizationSpecificFilterFields ?? {}, fields);
  const [organizationSpecificFilterKeys, filterKeys] = filterList.reduce<[string[], string[]]>(
    ([orgKeys, generalKeys], filterKey) => {
      if (STUDY_ORGANIZATION_SPECIFIC_FILTER_KEYS.includes(filterKey as StudyFilterName)) {
        return [[...orgKeys, filterKey], generalKeys];
      }
      return [orgKeys, [...generalKeys, filterKey]];
    },
    [[], []],
  );

  return {
    filterKeys,
    filterRemoveList,
    organizationSpecificFilterKeys,
    filterRemoveListOrganizationSpecific,
  };
};

export const getTemporalFilterKeysAndValues = (
  fields: Partial<StudyFilterFields>,
  filterFields: Partial<StudyFilterFields>,
) => {
  const filterList = getNonEmptyKeys(fields);
  const filterRemoveList = getEmptyKeysForRemoval(filterFields, fields);
  const filterKeys = filterList.filter((key) => !filterFields[key] || Array.isArray(filterFields[key]));

  return [...filterKeys, ...filterRemoveList];
};

export const getNewValues = <T extends keyof StudyFilterFields>(
  current: StudyFilterFields[T],
  previous: StudyFilterFields[T],
): StudyFilterFields[T] | undefined => {
  if (Array.isArray(current) && Array.isArray(previous)) {
    const newValues = current.filter((item) => !previous.some((prev) => prev.value === item.value));
    return newValues.length > 0 ? (newValues as StudyFilterFields[T]) : undefined;
  }

  return JSON.stringify(current) !== JSON.stringify(previous) ? current : undefined;
};

export const formatTemporalFilterValue = (value: unknown): unknown => {
  if (typeof value !== 'string') return value;

  const date = new Date(value);
  if (!isNaN(date.getTime())) return date.toISOString();

  return value;
};

export const getFilteredTemporalFields = (
  filterFields: Partial<StudyFilterFields>,
  temporalFields: Partial<StudyFilterFields>,
): Partial<StudyFilterFields> => {
  return Object.fromEntries(
    Object.entries(temporalFields)
      .map(([key, value]) => {
        const newValue = getNewValues(value, filterFields[key]);
        return [key, newValue];
      })
      .filter(([, value]) => isNotEmpty(value)),
  );
};
