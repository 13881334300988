/*
 * @deprecated
 * We need to separate the logic based on responsibilities
 */
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow';
import { useApolloClient } from '@apollo/client';

import { getOrganizationIdFromStorage } from '@eva-pacs/core';
import {
  useTokenAuthMutation,
  UserType,
  FlagType,
  UserNodeCustom,
  UserDataType,
  useGetVisitantTokenAuthMutation,
  ProductGroupType,
  MeDocument,
} from '@eva-pacs/client';
import { Account, useSessionStore } from '~/src/store';
import { getErrorByGraphqlKey } from '~/utils/appHelpers';
import { useLoadProductPermissionGroups } from '../loadProductPermissionGroups';
import { FeatureFlags } from '~/src/constants/featureFlags';
import { FeatureFlag, useFeatureFlagsStore } from '~/src/store/flagsStore';
import { productGroupsToProductRolesDTO } from '~/src/dtos/productGroupsToProductRoles.dto';
import { useProductRolesStore } from '~/src/store/useProductRolesStore';
import { SCREEN_PREFERENCES_SELECTED_ID_LOCAL_STORAGE_KEY } from '~/utils/screenPreferences';
import { useAuthContext } from '~/src/contexts/authContext';

export interface ServiceResponse {
  success: boolean;
  error: string | Error;
  data?: any;
}

export interface AuthService {
  /**
   * Log in by user data (user, password)
   */
  logInByUserData: (email: string, password: string, linkMethod?: boolean) => Promise<ServiceResponse>;

  /**
   * Log in visitant user (user, password)
   */
  loginVisitant: (email: string, password: string) => Promise<ServiceResponse>;

  /**
   * Log in by refresh token
   */
  logInByRefreshToken: (visitant: boolean) => Promise<ServiceResponse>;
}

/**
 * Centralized Auth logic hook
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-11-18
 */
export const useAuthService = (): AuthService => {
  // Hooks
  const { t } = useTranslation();
  const { loadProductPermissionGroups } = useLoadProductPermissionGroups();
  const setFlags = useFeatureFlagsStore((store) => store.setFlags);
  const { setTokenItem, refreshToken } = useAuthContext();
  const [setUser, setCurrentOrganizationId, setAccounts, setSelectedScreenPreferenceId] = useSessionStore(
    (state) => [state.setUser, state.setCurrentOrganizationId, state.setAccounts, state.setSelectedScreenPreferenceId],
    shallow,
  );
  const [login] = useTokenAuthMutation();
  const { setProductRoles } = useProductRolesStore();
  const [getVisitantToken] = useGetVisitantTokenAuthMutation();
  const apolloClient = useApolloClient();

  const saveUser = (user: UserNodeCustom, currentOrganizationId: string) => {
    const userToSave = formatAuthUser(user, currentOrganizationId);
    setUser(userToSave);
  };

  // Service actions
  const saveCurrentSession = (data: { user?: UserNodeCustom | null; visitant: boolean }) => {
    if (!data.user) return;
    const userOrganizationsData = (data.user.data ?? []) as Array<UserDataType>;
    const accounts = formatAccounts(userOrganizationsData);
    setAccounts(accounts);
    setSelectedScreenPreferenceId(localStorage.getItem(SCREEN_PREFERENCES_SELECTED_ID_LOCAL_STORAGE_KEY) || '');
    const currentOrganizationId = data.user.visitant
      ? data.user.visitant.organization.id
      : getCurrentOrganizationId(accounts);
    setCurrentOrganizationId(currentOrganizationId, data.visitant);
    saveProductRoles(currentOrganizationId, userOrganizationsData);
    saveUser(data.user, currentOrganizationId);
    const activeFlags = getFlags(data.user, currentOrganizationId);
    setFlags(formatFlags(activeFlags));
    if (data.user.visitant?.id) return;
    loadProductPermissionGroups();
  };

  const saveProductRoles = (currentOrganizationId: string, userOrganizationsData: Array<UserDataType>) => {
    const organizationData = userOrganizationsData.find(
      (userOrganizationData) => userOrganizationData.organization?.id === currentOrganizationId,
    );
    if (!organizationData) return;
    const productRoles = productGroupsToProductRolesDTO(organizationData.productGroups as Array<ProductGroupType>);
    setProductRoles(productRoles);
  };

  const loginVisitant = async (email: string, password: string): Promise<ServiceResponse> => {
    try {
      const { data } = await getVisitantToken({
        variables: {
          input: {
            email,
            password,
          },
        },
      });
      if (data?.tokenAuth?.success) {
        const { refreshToken, user, token, payload } = data.tokenAuth;
        setTokenItem({
          refresh: refreshToken,
          access: token,
          expirationTime: payload.exp * 1000,
        });
        saveCurrentSession({
          user: user as UserNodeCustom,
          visitant: true,
        });
        return { success: true, error: '' };
      }
      throw new Error(getErrorByGraphqlKey(t, data?.tokenAuth?.errors));
    } catch (error) {
      return { success: false, error: error as Error };
    }
  };

  const logInByUserData = async (email: string, password: string, linkMethod?: boolean): Promise<ServiceResponse> => {
    try {
      const { data } = await login({
        variables: {
          input: {
            email,
            password,
          },
        },
        ...(linkMethod && {
          context: {
            headers: {
              'link-method': 'allowed',
            },
          },
        }),
      });
      if (data?.tokenAuth?.success) {
        const { refreshToken, user, token, payload } = data.tokenAuth;
        setTokenItem({
          refresh: refreshToken,
          access: token,
          expirationTime: payload.exp * 1000,
        });
        saveCurrentSession({
          user: user as UserNodeCustom,
          visitant: false,
        });
        return { success: true, error: '' };
      }
      throw new Error(getErrorByGraphqlKey(t, data?.tokenAuth?.errors));
    } catch (error) {
      return { success: false, error: error as Error };
    }
  };

  const logInByRefreshToken = async (visitant: boolean): Promise<ServiceResponse> => {
    try {
      await refreshToken();
      const response = await apolloClient.query({
        query: MeDocument,
      });
      const me = response.data.me;
      saveCurrentSession({
        user: me as UserNodeCustom,
        visitant,
      });
      return { success: true, error: '' };
    } catch (error) {
      return { success: false, error: error as Error };
    }
  };

  // Export useAuthService values
  return { logInByUserData, logInByRefreshToken, loginVisitant };
};

const formatAuthUser = (user: UserNodeCustom, currentOrganizationId: string): UserType => {
  const currentAccount =
    user.data?.find((account) => account?.organization?.id === currentOrganizationId) ??
    ((user.data ?? []) as Array<UserDataType>)[0];
  return {
    ...user,
    id: atob(user?.id).split(':')[1],
    practitioners: user.data?.map((account) => account?.practitioner),
    productGroups: currentAccount?.productGroups,
  } as UserType;
};

const formatAccounts = (userData: Array<UserDataType>): Array<Account> =>
  userData.map((organizationGroup) => {
    return {
      organizationId: organizationGroup?.organization?.id as string,
      organizationName: organizationGroup?.organization?.name as string,
      practitionerId: organizationGroup?.practitioner?.id,
      userInfo: {
        fullName: organizationGroup?.practitioner?.fullName ?? '',
        name: organizationGroup?.practitioner?.name ?? '',
        lastName: organizationGroup?.practitioner?.firstSurname ?? '',
        phone: organizationGroup?.practitioner?.phone ?? '',
        phoneCode: organizationGroup?.practitioner?.phoneCode ?? '',
        gender: organizationGroup?.practitioner?.gender ?? '',
      },
      hasPendingPayment: organizationGroup?.organization?.hasPendingPayment || false,
      allowToCreateCustomStudyList: organizationGroup?.practitioner?.allowToCreateCustomStudyList || false,
    };
  });

const getCurrentOrganizationId = (accounts: Array<Account>): string => {
  const previousOrganizationId = getOrganizationIdFromStorage();
  const previousIsValid = accounts.find((account) => account.organizationId === previousOrganizationId);
  if (previousIsValid) return previousOrganizationId;
  return accounts[0].organizationId;
};

const formatFlags = (flags: Array<FlagType>): Array<FeatureFlag> =>
  flags.map((flag) => ({
    identifier: flag.identifier as FeatureFlags,
  }));

const getFlags = (user: UserNodeCustom, currentOrganizationId: string): Array<FlagType> => {
  const currentOrganization = user.data?.find((dataItem) => dataItem?.organization?.id === currentOrganizationId) ?? {};
  return (currentOrganization?.organization?.activeFlags ?? []) as Array<FlagType>;
};
