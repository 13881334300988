import { useEffect, useState, useCallback, useRef } from 'react';
import {
  FRESH_CHAT_SCRIPT_ID,
  FRESH_CHAT_SCRIPT_URL,
  FRESH_CHAT_WIDGET_CONTAINER_ID,
  FRESH_CHAT_PREVIEW_CONTAINER_CLASSNAME,
  ChatServiceStatus,
  FreshChatConfig,
  FreshChatWidget,
} from '~/constants';
import { createScriptElement } from '~/src/hooks/headService';
export interface UseCustomerSupportChatService {
  isActive?: boolean;
}
import { useUpdateOrganizationUserMutation, useGetUserOrganizationQuery } from '@eva-pacs/client';
import { useErrorHandler } from '~/utils/appHelpers';

export const useFreshChatService = ({ isActive }: UseCustomerSupportChatService) => {
  const { handleError } = useErrorHandler();
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
  const [isPopupPreviewOpen, setIsPopupPreviewOpen] = useState(false);

  const [chatConfig, setChatConfig] = useState<FreshChatConfig>();
  const widgetClassesObserverRef = useRef<MutationObserver | null>(null);
  const [freshChatWidget, setFreshChatWidget] = useState<FreshChatWidget | null>(
    typeof window !== 'undefined' ? window.fcWidget : null,
  );
  const [unreadNotifications, setUnreadNotifications] = useState(-1);
  const [chatServiceStatus, setChatServiceStatus] = useState<ChatServiceStatus>(ChatServiceStatus.NotLoaded);

  const [updateOrganizationUserMutation] = useUpdateOrganizationUserMutation({
    onError: (error) => handleError({ logMessage: error }),
  });
  const loadFreshChatScript = useCallback(() => {
    if (!isActive || !process.env.REACT_APP_FRESHCHAT_TOKEN) return;

    const script = createScriptElement(FRESH_CHAT_SCRIPT_ID, FRESH_CHAT_SCRIPT_URL, { async: true, defer: true });
    if (!script || freshChatWidget) return setChatServiceStatus(ChatServiceStatus.Loaded);
    script.onload = () => {
      setFreshChatWidget(window.fcWidget);
      setChatServiceStatus(ChatServiceStatus.Loaded);
    };
    document.head.appendChild(script);
  }, [isActive, freshChatWidget]);

  const initFreshChatService = useCallback(
    (config: FreshChatConfig) => {
      loadFreshChatScript();
      setChatConfig(config);
    },
    [loadFreshChatScript],
  );
  const updateOrganizationUser = useCallback(
    async (organization_id: string, user_id: string, freshchatRestoreId: string) => {
      const { data } = await updateOrganizationUserMutation({
        variables: {
          organization_id,
          user_id,
          input: { freshchatRestoreId },
        },
      });
      return data;
    },
    [updateOrganizationUserMutation],
  );

  const { data } = useGetUserOrganizationQuery({
    variables: { organization_id: chatConfig?.meta?.cf_organization_id, user_id: chatConfig?.meta?.user_id },
    fetchPolicy: 'no-cache',
    skip: !chatConfig,
  });

  useEffect(() => {
    if (data?.organizationUser?.freshchatRestoreId && chatConfig) {
      chatConfig.restoreId = data?.organizationUser?.freshchatRestoreId;
    }
  }, [data, chatConfig]);

  const setFreshChatListeners = useCallback(() => {
    if (!freshChatWidget) return;
    freshChatWidget.on('widget:loaded', () => {});
    window.fcWidget.on('message:sent', () => {
      freshChatWidget.user?.update({
        meta: {
          cf_organization_id: chatConfig?.meta?.cf_organization_id,
          cf_organizationname: chatConfig?.meta?.cf_organizationname,
          cf_practitioner_id: chatConfig?.meta?.cf_practitioner_id,
        },
      });
      freshChatWidget?.user?.get().then((response) => {
        updateOrganizationUser(
          chatConfig?.meta?.cf_organization_id || '',
          chatConfig?.meta?.user_id || '',
          response.data.restoreId,
        );
      });
    });
    freshChatWidget.on('widget:closed', () => setIsWidgetOpen(false));
    freshChatWidget.on('widget:opened', () => {
      setIsWidgetOpen(true);
    });

    freshChatWidget.on('unreadCount:notify', ({ count }) => {
      setUnreadNotifications(count);
    });
  }, [
    freshChatWidget,
    chatConfig?.meta?.cf_organization_id,
    chatConfig?.meta?.cf_organizationname,
    chatConfig?.meta?.cf_practitioner_id,
    chatConfig?.meta?.user_id,
    updateOrganizationUser,
  ]);

  useEffect(() => {
    if (chatServiceStatus !== 'loaded' || !chatConfig || !freshChatWidget) return;
    freshChatWidget?.init(chatConfig);
    setChatServiceStatus(ChatServiceStatus.Ready);
  }, [chatServiceStatus, freshChatWidget, chatConfig]);

  useEffect(() => {
    if (chatServiceStatus === ChatServiceStatus.Ready) setFreshChatListeners();
  }, [loadFreshChatScript, initFreshChatService, setFreshChatListeners, chatServiceStatus]);

  useEffect(() => {
    /* Freshchat does not provide us with a status to know that the preview
     window for notifications is open. So using the mutation observer we look
     for when the div appears with the preview class and we use it
     to know that said screen is being displayed
     */
    if (typeof MutationObserver !== 'undefined' && !widgetClassesObserverRef.current) {
      //Finding the freshchat container div injected by the script
      const fcFrame = document.getElementById(FRESH_CHAT_WIDGET_CONTAINER_ID);
      if (!fcFrame) return;
      const fcFrameMutationObserver = new MutationObserver(function (mutationsList) {
        // Itera sobre las mutaciones observadas
        for (const mutation of mutationsList) {
          if (mutation.type !== 'attributes' || mutation.attributeName !== 'class') return;
          if ((mutation.target as HTMLElement).classList.contains(FRESH_CHAT_PREVIEW_CONTAINER_CLASSNAME)) {
            setIsPopupPreviewOpen(true);
            return;
          }
          setIsPopupPreviewOpen(false);
        }
      });

      // eslint-disable-next-line i18next/no-literal-string
      const observerOptions = { attributes: true, attributeFilter: ['class'] };
      fcFrameMutationObserver.observe(fcFrame, observerOptions);
      widgetClassesObserverRef.current = fcFrameMutationObserver;
    }
  }, [unreadNotifications]);

  return {
    isWidgetOpen,
    unreadNotifications,
    chatServiceStatus,
    initFreshChatService,
    freshChatWidget,
    isPopupPreviewOpen,
  };
};
