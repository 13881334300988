export var MENU_ITEM_EDIT = 'report';
export var MENU_ITEM_EDIT_REPORT = 'edit report';
export var MENU_ITEM_PREVIEW = 'preview';
export var MENU_ITEM_REPORT = 'edit';
export var MENU_ITEM_QR = 'qr';
export var MENU_ITEM_DEMAND = 'demand';
export var MENU_ITEM_FILES = 'files';
export var MENU_ITEM_ZIP_DOWNLOAD = 'download';
export var MENU_ITEM_BURN_ISO = 'burn';
export var MENU_ITEM_STUDY_DELETE = 'delete';
export var MENU_ITEM_UPDATE_DOUBLE_SIGNATURE = 'double signature';
export var MENU_ITEM_REPORT_PREVIEW = 'report preview';
export var MENU_ITEM_SEAL_STUDY = 'seal_study';
