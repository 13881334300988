import dicomParser from 'dicom-parser';
import cornerstone from 'cornerstone-core';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
import cornerstoneMath from 'cornerstone-math';
import cornerstoneTools from 'cornerstone-tools';
import Hammer from 'hammerjs';

import {
  LINE_COLOR,
  PASSIVE_LINE_COLOR,
  ANNOTATION_FONT_SIZE,
  ANNOTATION_FONT_FAMILY,
  ANNOTATION_LINE_WIDTH,
} from '~/constants';
import { isMobileDevice } from '@eva-pacs/core';
import { registerImageLoaders as registerCustomImageLoaders } from './customImageLoaders/registerImageLoaders';
import stackPrefetch from './cornestone/stackPrefetch';

export type globalVariables = Window &
  typeof globalThis & {
    imagerPixelSpacing: string | undefined;
    pixelSpacing: string | undefined;
  };
/**
 * Handles the initialization of cornerstone
 *
 * @author Mario Lugo<mariolugo23@gmail.com>
 *
 * Created at 2021-04-05
 */
export default function initCornerstone() {
  // Debug
  window.cornerstone = cornerstone;
  window.cornerstoneTools = cornerstoneTools;

  const defaultCsToolsConfig = {
    globalToolSyncEnabled: true,
    showSVGCursors: true,
    autoResizeViewports: false,
  };
  // Cornertone Tools
  cornerstoneTools.external.cornerstone = cornerstone;
  cornerstoneTools.external.Hammer = Hammer;
  cornerstoneTools.external.cornerstoneMath = cornerstoneMath;

  cornerstoneTools.init(defaultCsToolsConfig);

  cornerstoneTools.loadHandlerManager.setErrorLoadingHandler((error) => {
    throw error;
  });

  // Preferences
  cornerstoneTools.textStyle.setFont(`${ANNOTATION_FONT_SIZE}px ${ANNOTATION_FONT_FAMILY}`);
  cornerstoneTools.toolStyle.setToolWidth(ANNOTATION_LINE_WIDTH);

  cornerstoneTools.toolColors.setToolColor(LINE_COLOR);
  cornerstoneTools.toolColors.setActiveColor(PASSIVE_LINE_COLOR);

  cornerstoneTools.store.state.touchProximity = 40;

  cornerstoneTools.loadHandlerManager.setErrorLoadingHandler((element, imageId, error) => {
    console.error(element, imageId);
    throw error;
  });

  cornerstoneTools.stackPrefetch = stackPrefetch;
  // Configure stack prefetch
  cornerstoneTools.stackPrefetch.setConfiguration({
    maxImagesToPrefetch: Number.POSITIVE_INFINITY,
    preserveExistingPool: false,
    maxSimultaneousRequests: isMobileDevice() ? 1 : 5,
  });

  /**
   * This is used to process the metadata from the binary dicom files
   * Dicom parser is used to parse all the required metadata from cornerstone
   *
   * This web worker framework is used for image decompression but is
   * designed to be usable for custom tasks as well.
   *
   *
   * Refer to:
   * https://github.com/cornerstonejs/cornerstoneWADOImageLoader
   * https://github.com/cornerstonejs/cornerstoneWADOImageLoader/blob/master/docs/WebWorkers.md
   *
   */
  cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
  cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

  cornerstoneWADOImageLoader.webWorkerManager.initialize({
    maxWebWorkers: navigator.hardwareConcurrency || 1,
    startWebWorkersOnDemand: true,
    webWorkerTaskPaths: [],
    taskConfiguration: {
      decodeTask: {
        initializeCodecsOnStartup: false,
        usePDFJS: false,
        strict: false,
      },
    },
  });

  cornerstoneWebImageLoader.external.cornerstone = cornerstone;
  registerCustomImageLoaders();
}
