import { TagSpecialtyType } from '@eva-pacs/client';
import { getTextFromMaybeType } from '@eva-tech/graph-driven-ui';
import { Specialty } from '../types/Specialty';

export const specialtiesDTO = (specialty: TagSpecialtyType | null): Specialty | null => {
  if (!specialty) return null;

  return {
    id: specialty.id,
    name: specialty.name,
    description: getTextFromMaybeType(specialty.description),
  };
};
