import React, { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { useHorizontalScroll } from '@evacenter/eden';
var MIN_SCROLL_PERCENTAGE = 0;
var MAX_SCROLL_PERCENTAGE = 100;
/**
 * Toolbar can be used to group related options. To emphasize groups of related icon buttons, a toolbar should share a common container.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-04-22
 */
var Toolbar = function (_a) {
    var leftSection = _a.leftSection, middleSection = _a.middleSection, rightSection = _a.rightSection, className = _a.className;
    var overflowRef = useRef(null);
    var _b = useState(0), scrollPercentage = _b[0], setScrollPercentage = _b[1];
    var _c = useState(false), isScrollable = _c[0], setIsScrollable = _c[1];
    useHorizontalScroll(overflowRef, isScrollable);
    var classes = {
        toolbarClass: cn(className, 'e-flex e-items-center bg-gray-900 e-w-full hide-scrollbar e-relative e-overflow-y-scroll'),
        gradientBase: cn('e-h-full e-w-18 from-gray-900 to-transparent'),
        shadowContainer: cn('e-absolute e-h-full e-top-0 e-flex e-z-1'),
    };
    useEffect(function () { return updateScrollable(); }, [overflowRef, leftSection, middleSection, rightSection]);
    useEffect(function () {
        document.addEventListener('resize', updateScrollable);
        return function () { return document.removeEventListener('resize', updateScrollable); };
    }, []);
    var showLeftArrow = useMemo(function () {
        if (isScrollable && scrollPercentage > MIN_SCROLL_PERCENTAGE)
            return true;
        return false;
    }, [isScrollable, scrollPercentage]);
    var showRightArrow = useMemo(function () {
        if (isScrollable && scrollPercentage < MAX_SCROLL_PERCENTAGE)
            return true;
        return false;
    }, [isScrollable, scrollPercentage]);
    var updateScrollable = function () {
        var element = overflowRef.current;
        if (!element)
            return setIsScrollable(false);
        var result = element.scrollWidth > element.clientWidth;
        setIsScrollable(result);
    };
    var handleScroll = function () {
        var element = overflowRef.current;
        if (!element)
            return 0;
        var scrollLeft = element.scrollLeft;
        var scrollWidth = element.scrollWidth - element.clientWidth;
        var scrollPercentage = (scrollLeft / scrollWidth) * 100;
        var intPercentage = Math.floor(scrollPercentage);
        setScrollPercentage(intPercentage);
    };
    return (React.createElement("section", { className: "e-flex e-relative e-items-center e-w-full hide-scrollbar" },
        showLeftArrow && (React.createElement("div", { className: cn(classes.shadowContainer, 'e-left-0') },
            React.createElement("div", { className: cn(classes.gradientBase, 'bg-gradient-to-r') }))),
        React.createElement("div", { className: classes.toolbarClass, ref: overflowRef, onScroll: handleScroll },
            React.createElement("div", { className: "e-flex e-items-center e-justify-start" }, leftSection),
            React.createElement("div", { className: "flex flex-grow items-center justify-center" }, middleSection),
            React.createElement("div", { className: "e-flex e-items-center e-justify-end e-gap-2" }, rightSection)),
        showRightArrow && (React.createElement("div", { className: cn(classes.shadowContainer, 'e-right-0') },
            React.createElement("div", { className: cn(classes.gradientBase, 'bg-gradient-to-l') })))));
};
export default Toolbar;
