import cornerstone from 'cornerstone-core';
import csTools from 'cornerstone-tools';
import { getEnabledElementControlled } from '../getEnabledElementControlled';
import { useMeasurementStore, useToolStoreConfig, useViewportStore } from '~/src/store';
import { drawHeatmap } from './heatmap/drawHeatmap';
import { combineHeatmaps } from './heatmap/combineHeatmaps';
import { gaussianBlur } from './heatmap/applyBlur';
import { scaleMatrix } from '~/utils/scaleMatrix';
import { ToolName } from '~/src/constants/toolName';
const BaseTool = csTools.importInternal('base/BaseTool');
/**
 *
 * http://dicom.nema.org/dicom/2013/output/chtml/part03/sect_C.9.html
 *
 * @public
 * @class HeatmapDrawer
 * @memberof Tools
 * @author Mauricio Campos <mau.cdr.19@gmail.com>
 * @classdesc Tool for displaying a heatmap.
 * @extends Tools.Base.BaseTool
 */

const cachedMaps: Record<string, PreCalculatedHeatmap> = {};

interface PreCalculatedHeatmap {
  offScreenCanvas: HTMLCanvasElement;
  mapId: string;
  opacity?: number;
}
export class HeatmapDrawer extends BaseTool {
  configuration;
  invalidHeatMaps: boolean;

  constructor(props = {}) {
    const defaultProps = {
      name: ToolName.HeatmapDrawer,
      mixins: ['enabledOrDisabledBinaryTool'],
    };
    super(props, defaultProps);
    this.invalidHeatMaps = true;
  }

  enabledCallback(element) {
    this.forceImageUpdate(element);
  }

  disabledCallback(element) {
    this.forceImageUpdate(element);
  }

  setConfiguration(configuration) {
    this.configuration = {
      ...this.configuration,
      ...configuration,
    };
    // it the opacity changes we need to invalidate the heatmaps to recalculate them
    if (typeof configuration.opacity === 'number') this.invalidHeatMaps = true;
    cornerstone.getEnabledElements().forEach((enabledElement) => {
      this.forceImageUpdate(enabledElement.element);
    });
  }

  getConfiguration() {
    return this.configuration;
  }

  forceImageUpdate(element) {
    const enabledElement = getEnabledElementControlled(element);

    if (!enabledElement?.image) return;

    cornerstone.updateImage(element);
  }

  createMapId(measurements) {
    return measurements.map((measurement) => measurement.id).join('-');
  }

  getHeatMaps(image, heatmaps) {
    const offScreenCanvas = document.createElement('canvas');
    offScreenCanvas.width = image.width;
    offScreenCanvas.height = image.height;
    const offScreenCanvasContext = offScreenCanvas.getContext('2d');
    if (!offScreenCanvasContext) return;

    const decodedMaps = heatmaps.map((map) => JSON.parse(map.heatmap).coordinates).filter((matrix) => matrix);
    const { width, height } = image;
    const combineRawdMatrix = combineHeatmaps(...decodedMaps);
    const combinedMatrix = combineRawdMatrix;
    const testMatrix = gaussianBlur(combinedMatrix);

    //Scale the matrix to the image size
    const imageHeatmaps = scaleMatrix(testMatrix, height, width);
    //Draw the heatmap in the offset canvas
    drawHeatmap(offScreenCanvasContext, imageHeatmaps, {
      opacity: useToolStoreConfig.getState().heatmapConfig.opacity,
    });

    const precalculatedHeatmap: PreCalculatedHeatmap = {
      offScreenCanvas,
      mapId: this.createMapId(heatmaps),
      opacity: this.configuration.opacity,
    };
    this.invalidHeatMaps = false;
    cachedMaps[image.imageId] = precalculatedHeatmap;
    return precalculatedHeatmap;
  }

  shouldRecalculateHeatmaps(heatmap: PreCalculatedHeatmap | undefined, measurements) {
    if (!heatmap || this.invalidHeatMaps) return true;
    return heatmap.mapId !== this.createMapId(measurements);
  }

  getCurrentImage(element) {
    const currentviewport = Object.values(useViewportStore.getState().viewportData).find(
      (viewport) => viewport.dom === element,
    );
    if (!currentviewport) return;
    return currentviewport.lastImageIdLoaded;
  }

  renderToolData(evt) {
    if (!useToolStoreConfig.getState().heatmapConfig.visible) return;

    const eventData = evt.detail;

    const { enabledElement, image, canvasContext, element } = eventData;
    if (!eventData || !enabledElement) return;
    const currentImageId = this.getCurrentImage(element);
    if (!currentImageId) return;
    const visibleHeatmaps = useMeasurementStore
      .getState()
      .getAllMeasurements()
      .filter((measurement) => currentImageId === image.imageId && measurement.data.visible && measurement.heatmap);
    if (!visibleHeatmaps.length) return;
    let heatmapToDraw: PreCalculatedHeatmap | undefined = cachedMaps[currentImageId];

    if (this.shouldRecalculateHeatmaps(heatmapToDraw, visibleHeatmaps)) {
      heatmapToDraw = this.getHeatMaps(image, visibleHeatmaps);
    }
    if (!heatmapToDraw) return;

    canvasContext.drawImage(heatmapToDraw.offScreenCanvas, 0, 0);
  }
}
