import { useEffect } from 'react';

import {
  MeasurementType,
  StudyType,
  StudyThumbnailType,
  useGetStudiesHistoryByPatientNameLazyQuery,
  useViewerStudyLazyQuery,
} from '@eva-pacs/client';
import { isMobileDevice } from '@eva-pacs/core';
import { ApolloError, WatchQueryFetchPolicy } from '@apollo/client';

import { useFeatureFlagsStore, useMeasurementStore, useSeriesStore, useSessionStore, useStudyStore } from '~/src/store';
import { CAN_READ_MEASUREMENT, FeatureFlags, CAN_SEE_AI, POLICIES } from '~/constants';
import { shallow } from 'zustand/shallow';
import { useLoadViewerSeries } from '../series/loadViewerSeries';
import { useErrorHandler } from '~/utils/appHelpers';
import { formatMeasurements } from '~/utils/cornestone/formatMeasurements';
import { Study, useStudiesStore } from '@eva-tech/viewers-studies';
import { getReferringPractitionerName } from '~/utils/getReferringPractitionerName';

export interface GetViewerStudyProps {
  onSuccess?: (study: StudyType) => void;
  onError: (error: ApolloError) => void;
  studyId: string;
  credentials?: string;
  fetchPolicy?: WatchQueryFetchPolicy;
}
/** Hook to get a study from the server */
export const useGetViewerStudy = ({ studyId, credentials, fetchPolicy, onSuccess, onError }: GetViewerStudyProps) => {
  const addStudy = useStudiesStore((store) => store.addStudy);
  const hasFlag = useFeatureFlagsStore((store) => store.hasFlag);
  const isNewSeriesBarEnabled = hasFlag(FeatureFlags.EnableNewSeriesBar) && !isMobileDevice();
  const [addMeasurement, setMeasurementsLoaded] = useMeasurementStore(
    (store) => [store.addMeasurement, store.setMeasurementsLoaded],
    shallow,
  );
  const [study, setStudy] = useStudyStore((store) => [store.study, store.setStudy], shallow);
  const { handleError } = useErrorHandler();
  const getLoadedSeries = useSeriesStore((store) => store.getLoadedSeries);
  const user = useSessionStore((store) => store.user);

  const canSeeAi = POLICIES[CAN_SEE_AI](user);

  const { loadSerieMeasurements } = useLoadViewerSeries({ onError: (error) => handleError({ logMessage: error }) });
  const [getStudiesHistoryByPatientName] = useGetStudiesHistoryByPatientNameLazyQuery({
    onCompleted: ({ studiesHistory }) => {
      if (!studiesHistory) return;
      studiesHistory.forEach((study) => study && addStudy(formatStudy(study as StudyThumbnailType)));
    },
  });

  const [getViewerStudy] = useViewerStudyLazyQuery({
    fetchPolicy,
    onError,
    onCompleted: ({ study }) => handleOnCompleteStudy(study as StudyType),
  });

  const loadMeasurements = (measurements: Array<MeasurementType>) => {
    const formatedMeasurements = formatMeasurements(measurements);
    formatedMeasurements.forEach((measurement) => {
      if (!canSeeAi && measurement.isAiGenerated) return;
      addMeasurement(measurement);
    });
    setMeasurementsLoaded();
    getLoadedSeries().forEach((serie) => loadSerieMeasurements(serie));
  };

  const handleOnCompleteStudy = (study: StudyType) => {
    if (!study) return;
    if (POLICIES[CAN_READ_MEASUREMENT](user)) loadMeasurements(study.measurements);
    if (study.patient.fullName && isNewSeriesBarEnabled)
      getStudiesHistoryByPatientName({ variables: { fullName: study.patient.fullName } });
    setStudy(study);
    if (onSuccess) onSuccess(study);
  };

  const handleRefetchStudy = () => getViewerStudy({ variables: { id: studyId, credentials } });

  // Get study if it is not in the store
  useEffect(() => {
    if (study?.id !== studyId) handleRefetchStudy();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials, study, studyId]);

  return { refetchStudy: handleRefetchStudy };
};
const formatStudy = (study: StudyThumbnailType): Study => ({
  id: study.id,
  patient: {
    id: study.patient.id,
    fullName: study.patient.fullName,
    birthDate: study.patient.birthDate,
    identifierEditable: study.patient.identifierEditable,
  },
  referringPractitioner: {
    fullName: getReferringPractitionerName(study),
  },
  dicomDescription: study.dicomDescription,
  dicomDateTimeEditable: study?.dicomDateTimeEditable,
  dicomReason: study?.dicomReason ?? '',
  practitionerAssigned: {
    fullName: study?.practitionerAssigned?.fullName,
  },
});
