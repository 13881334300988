import csTools from 'cornerstone-tools';
import { scroll } from '../scroll';

/**
 * @public
 * @class StackScrollMouseWheelTool
 * @memberof Tools
 *
 * @classdesc Tool for scrolling through a series using the mouse wheel.
 * @extends Tools.Base.BaseTool
 */

const SCROLL_INTENSITY_LOW = 2;
const SCROLL_INTENSITY_MEDIUM = 3;
const SCROLL_INTENSITY_HIGH = 4;
const MIN_SCROLL_STEP_INTENSITY = 0.7;
export default class StackScrollMouseWheelTool extends csTools.StackScrollMouseWheelTool {
  configuration;
  displacement = 0;
  // @ts-ignore
  constructor(props = {}) {
    const defaultProps = {
      name: 'StackScrollMouseWheel',
      supportedInteractionTypes: ['MouseWheel'],
      configuration: {
        loop: false,
        allowSkipping: true,
        invert: false,
        considerScrollIntensity: true,
      },
    };

    super(props, defaultProps);
  }

  mouseWheelCallback(evt) {
    const { direction: images, element } = evt.detail;
    const { loop, allowSkipping, invert } = this.configuration;
    let stepSize = 1;

    if (evt.detail) {
      const intesity = Math.abs(evt.detail.spinY);

      if (intesity > SCROLL_INTENSITY_LOW) {
        stepSize = 2;
      }
      if (intesity > SCROLL_INTENSITY_MEDIUM) {
        stepSize = 3;
      }
      if (intesity > SCROLL_INTENSITY_HIGH) {
        stepSize = 4;
      }
    }

    const direction = (invert ? -images : images) * stepSize;
    this.displacement += evt.detail.spinY;
    if (Math.abs(this.displacement) >= MIN_SCROLL_STEP_INTENSITY) {
      scroll(element, direction, loop, allowSkipping);
      this.displacement = 0;
    } else {
      // Send scroll event to prevent accidental trigger of the other even like zoom
      scroll(element, 0, loop, allowSkipping);
    }
  }
}
