import React, { useEffect } from 'react';

import { useReportAndPatientFromStudyQuery } from '@eva-pacs/client';
import { usePreviewReportMutation } from '@eva-pacs/client';
import { PreviewPDF, Spinner } from '@eva-pacs/ui';

import { useErrorHandler } from '~/utils/appHelpers';
import { USER_EVENT_PAGE_LOCATION, USER_EVENT_TYPES } from '~/constants';
import { useUserEventLogs } from '~/hooks';

export interface PreviewPDFWithoutSignatureWrapperProps {
  /**
   * Study id
   */
  studyId: string;

  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Show the send button
   */
  showSendButton?: boolean;

  /**
   * Show the print button
   */
  showPrintButton?: boolean;

  /**
   * Show the download button
   */
  showDownloadButton?: boolean;
}

/**
 * Use this component for user to preview unsigned PDF
 * @author Antonio Torres<toniotgz@gmail.com>
 * Created at 2022-09-02
 */
export const PreviewPDFWithoutSignatureWrapper: React.FC<PreviewPDFWithoutSignatureWrapperProps> = ({
  studyId,
  showSendButton = false,
  showPrintButton = false,
  showDownloadButton = false,
}) => {
  const { handleError } = useErrorHandler();
  const { logUserEvent } = useUserEventLogs();

  const { ...reportFromStudy } = useReportAndPatientFromStudyQuery({
    variables: {
      id: studyId,
    },
    onError: (error) => handleError({ logMessage: error }),
    fetchPolicy: 'no-cache',
  });

  // Create ISO file
  const [previewReportMutation, { data: previewResponse, loading: isPreviewLoading }] = usePreviewReportMutation({
    onError: (error) => handleError({ logMessage: error }),
    fetchPolicy: 'no-cache',
  });

  const [firstReport] = reportFromStudy.data?.study?.reports ?? [];

  const reportId = firstReport?.id;

  useEffect(() => {
    if (reportId) previewReportMutation({ variables: { id: reportId } });
  }, [previewReportMutation, reportId]);

  const pdfPreviewUrl = previewResponse?.previewReport.pdfPreviewUrl ?? '';

  if (!pdfPreviewUrl || isPreviewLoading) return <Spinner />;

  const handleOnDownloadPdf = () =>
    logUserEvent(USER_EVENT_TYPES.DOWNLOAD_REPORT, studyId, USER_EVENT_PAGE_LOCATION.PACS_PREVIEW_PDF_LOCATION);

  const handleOnDefaultPrint = () =>
    logUserEvent(USER_EVENT_TYPES.PRINT_DEFAULT_REPORT, studyId, USER_EVENT_PAGE_LOCATION.PACS_PREVIEW_PDF_LOCATION);

  const handleOnLetterheadPrint = () =>
    logUserEvent(USER_EVENT_TYPES.PRINT_LETTERHEAD_REPORT, studyId, USER_EVENT_PAGE_LOCATION.PACS_PREVIEW_PDF_LOCATION);

  return (
    <div>
      <PreviewPDF
        onDefaultPrint={handleOnDefaultPrint}
        onLetterheadPrint={handleOnLetterheadPrint}
        onDownloadPdf={handleOnDownloadPdf}
        pdfFile={pdfPreviewUrl}
        pdfLetterheadFile={pdfPreviewUrl}
        showDownloadButton={showDownloadButton}
        showPrintButton={showPrintButton}
        showSendButton={showSendButton}
      />
    </div>
  );
};
