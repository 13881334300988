var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import en from './en';
import es from './es';
import pt from './pt';
import enNewManagement from './en/NewManagement.json';
import esNewManagement from './es/NewManagement.json';
import ptNewManagement from './pt/NewManagement.json';
export default {
    en: {
        translation: __assign({}, en),
        common: __assign({}, enNewManagement),
    },
    es: {
        translation: __assign({}, es),
        common: __assign({}, esNewManagement),
    },
    pt: {
        translation: __assign({}, pt),
        common: __assign({}, ptNewManagement),
    },
};
