/**
 * Scales a 2D matrix (e.g., an image or grid) to a new height and width using bilinear interpolation.
 *
 * This function resizes a matrix from its original dimensions (oldHeight, oldWidth) to new dimensions
 * (newHeight, newWidth). It uses bilinear interpolation to compute values for the new matrix, allowing
 * for smooth resizing.
 *
 * @param matrix - A 2D array representing the original matrix (image/grid) to be resized.
 *                The matrix should have at least one row and one column.
 * @param newHeight - The desired height of the new matrix.
 * @param newWidth - The desired width of the new matrix.
 *
 * @returns A new 2D array (matrix) scaled to the new height and width. If the input matrix is empty
 *          or has no columns/rows, an empty array is returned.
 *
 * @example
 * const matrix = [
 *   [1, 2, 3],
 *   [4, 5, 6],
 *   [7, 8, 9]
 * ];
 * const newMatrix = scaleMatrix(matrix, 5, 5);
 * console.log(newMatrix); // A 5x5 matrix with scaled values.
 *
 * @remarks
 * - The function first checks if the input matrix has any data. If not, it returns an empty array.
 * - For matrices with a single element, it replicates that value across the new matrix.
 * - Bilinear interpolation is used for matrices with more than one element, ensuring smoother scaling.
 */
export const scaleMatrix = (matrix: number[][], newHeight: number, newWidth: number): number[][] => {
  // Check if the input matrix is empty or has no valid rows/columns
  if (matrix.length === 0 || matrix[0].length === 0) return [];

  const oldHeight = matrix.length;
  const oldWidth = matrix[0].length;

  // If the matrix is a single element, replicate the value across the new matrix dimensions
  if (oldHeight === 1 && oldWidth === 1) {
    return Array.from({ length: newHeight }, () => Array(newWidth).fill(matrix[0][0]));
  }

  // Calculate scaling factors for X and Y axes based on the new and old dimensions
  const scaleX = oldWidth > 1 ? (oldWidth - 1) / (newWidth - 1) : 0;
  const scaleY = oldHeight > 1 ? (oldHeight - 1) / (newHeight - 1) : 0;

  const newMatrix = Array.from({ length: newHeight }, () => new Array(newWidth).fill(0));

  for (let newY = 0; newY < newHeight; newY++) {
    for (let newX = 0; newX < newWidth; newX++) {
      // Calculate the corresponding coordinates in the original matrix
      const srcX = newX * scaleX;
      const srcY = newY * scaleY;
      const x0 = Math.floor(srcX);
      const y0 = Math.floor(srcY);
      const x1 = Math.min(x0 + 1, oldWidth - 1);
      const y1 = Math.min(y0 + 1, oldHeight - 1);

      // Calculate the differences (dx, dy) for interpolation
      const dx = srcX - x0;
      const dy = srcY - y0;

      const value =
        (1 - dx) * (1 - dy) * matrix[y0][x0] +
        dx * (1 - dy) * matrix[y0][x1] +
        (1 - dx) * dy * matrix[y1][x0] +
        dx * dy * matrix[y1][x1];

      newMatrix[newY][newX] = value;
    }
  }

  return newMatrix;
};
