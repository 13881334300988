/**
 * Applies a Gaussian blur to the input matrix using a specified kernel size and sigma value.
 *
 * @param matrix - The 2D matrix (image) to be blurred.
 * @param kernelSize - The size of the Gaussian kernel (default is 3).
 * @param sigma - The standard deviation of the Gaussian distribution (default is 1).
 * @returns The blurred 2D matrix (image).
 */
export const gaussianBlur = (matrix: Array<Array<number>>, kernelSize = 3, sigma = 1) => {
  const kernel = createGaussianKernel(kernelSize, sigma);
  return applyConvolution(matrix, kernel);
};

/**
 * Creates a Gaussian kernel of the specified size and sigma value.
 * The kernel is generated based on the Gaussian distribution formula and normalized.
 *
 * @param size - The size of the kernel (e.g., 3x3, 5x5).
 * @param sigma - The standard deviation of the Gaussian distribution.
 * @returns The Gaussian kernel as a 2D array.
 */
const createGaussianKernel = (size: number, sigma: number): Array<Array<number>> => {
  const kernel = Array.from({ length: size }, () => new Array(size).fill(0));
  const mean = Math.floor(size / 2);
  let sum = 0;

  // Calculate the values of the Gaussian kernel based on the Gaussian distribution
  for (let x = 0; x < size; x++) {
    for (let y = 0; y < size; y++) {
      kernel[x][y] = Math.exp(-((x - mean) ** 2 + (y - mean) ** 2) / (2 * sigma ** 2));
      sum += kernel[x][y];
    }
  }

  // Normalize the kernel so that the sum of all its values is 1
  for (let x = 0; x < size; x++) {
    for (let y = 0; y < size; y++) {
      kernel[x][y] /= sum;
    }
  }

  return kernel;
};

/**
 * Applies convolution to the input matrix with the given kernel.
 * This function slides the kernel over the input matrix and performs the convolution operation.
 *
 * @param matrix - The 2D matrix (image) to apply the convolution to.
 * @param kernel - The convolution kernel (e.g., Gaussian kernel).
 * @returns The resulting 2D matrix after the convolution.
 */
const applyConvolution = (matrix: Array<Array<number>>, kernel: Array<Array<number>>): Array<Array<number>> => {
  const kernelSize = kernel.length;
  const pad = Math.floor(kernelSize / 2);
  const width = matrix.length;
  const height = matrix[0].length;
  const result: Array<Array<number>> = Array.from({ length: width }, () => new Array(height).fill(0));

  for (let x = pad; x < width - pad; x++) {
    for (let y = pad; y < height - pad; y++) {
      let sum = 0;

      // Apply the kernel over the corresponding region of the matrix
      for (let i = 0; i < kernelSize; i++) {
        for (let j = 0; j < kernelSize; j++) {
          sum += matrix[x + i - pad][y + j - pad] * kernel[i][j];
        }
      }

      result[x][y] = sum;
    }
  }

  return result;
};
