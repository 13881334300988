import React, { useState } from 'react';
import cn from 'classnames';
import printJS from 'print-js';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf';
import { getMediaUrl } from '@eva-pacs/core';
import { Button, ButtonColor, ButtonVariant, IconCatalog, DropdownMenu, Menu, MenuPlacement, Item } from '../../atoms';
/**
 * Use this component in order to show a Pdf preview
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-06-24
 */
export var PreviewPDF = function (_a) {
    var className = _a.className, pdfFile = _a.pdfFile, pdfLetterheadFile = _a.pdfLetterheadFile, _b = _a.showSendButton, showSendButton = _b === void 0 ? true : _b, _c = _a.showPrintButton, showPrintButton = _c === void 0 ? true : _c, _d = _a.showDownloadButton, showDownloadButton = _d === void 0 ? true : _d, onPrimaryAction = _a.onPrimaryAction, onDownloadPdf = _a.onDownloadPdf, onDefaultPrint = _a.onDefaultPrint, onLetterheadPrint = _a.onLetterheadPrint;
    var previewPDFClass = cn(className, 'scrollbar-w-2 scrollbar-thumb-gray-200 scrollbar-thumb-rounded-lg', 'overflow-x-hidden overflow-y-auto');
    var t = useTranslation().t;
    var _e = useState(null), numPages = _e[0], setNumPages = _e[1];
    var _f = useState(1), scale = _f[0], setScale = _f[1];
    var handleZoomOutBtnClick = function () {
        if (scale > 0.25)
            setScale(function (prev) { return prev - 0.25; });
    };
    var handleZoomInBtnClick = function () {
        if (scale < 5)
            setScale(function (prev) { return prev + 0.25; });
    };
    var handleDocumentLoadSuccess = function (_a) {
        var numPages = _a.numPages;
        return setNumPages(numPages);
    };
    var handlePrimaryAction = function () {
        if (onPrimaryAction)
            onPrimaryAction();
    };
    var handleDownload = function (event) {
        if (onDownloadPdf)
            onDownloadPdf();
        event.preventDefault();
        window.open(pdfFile);
    };
    var handlePrint = function (pageUrl) { return function () { return printJS(getMediaUrl(pageUrl)); }; };
    var handleDefaultPrint = function (pageUrl) {
        if (onDefaultPrint)
            onDefaultPrint();
        handlePrint(pageUrl)();
    };
    var handleLetterHeadPrint = function (pageUrl) {
        if (onLetterheadPrint)
            onLetterheadPrint();
        handlePrint(pageUrl)();
    };
    return (React.createElement("div", { className: "relative" },
        React.createElement("div", { className: "flex pb-4 px-4 w-full" },
            React.createElement("div", { className: "flex items-center ml-auto" },
                showDownloadButton && (React.createElement(Button, { leftIcon: IconCatalog.download, isRounded: true, variant: ButtonVariant.text, color: ButtonColor.dark, onClick: handleDownload }, t('general.actions.downloadPdf'))),
                showPrintButton && (React.createElement(DropdownMenu, null,
                    React.createElement(Button, { leftIcon: IconCatalog.print, rightIcon: IconCatalog.chevronDown, isRounded: true, variant: ButtonVariant.text, color: ButtonColor.dark, onClick: handleZoomOutBtnClick }, t('general.actions.print')),
                    React.createElement(Menu, { className: "border-extra-dark-snow z-10 bg-gray-900 border", placement: MenuPlacement.bottomLeft },
                        React.createElement(Item, { className: "w-56 text-basic-white hover:bg-gray-600", onClick: function () { return handleLetterHeadPrint(pdfLetterheadFile); } }, t('viewer.report.letterheadPrint')),
                        React.createElement(Item, { className: "w-56 text-basic-white hover:bg-gray-600", onClick: function () { return handleDefaultPrint(pdfFile); } }, t('viewer.report.defaultPrint'))))))),
        React.createElement("div", { className: previewPDFClass, style: { height: 'calc(100vh - 240px)' } },
            React.createElement(Document, { className: "flex flex-col mb-20 pr-3 space-y-3", file: pdfFile, onLoadSuccess: handleDocumentLoadSuccess, loading: React.createElement("div", { className: "text-basic-white text-label-md" }, t('general.loading')) }, Array.from(new Array(numPages), function (_, index) { return (React.createElement(Page, { key: "page_".concat(index + 1), renderAnnotationLayer: false, pageNumber: index + 1, scale: scale })); })),
            React.createElement("div", { className: "fixed bottom-0 left-0 flex justify-between justify-items-center p-4 w-full bg-gray-700 border-t border-gray-400" },
                React.createElement("div", { className: "flex items-center space-x-3" },
                    React.createElement(Button, { leftIcon: IconCatalog.decrease, isRounded: true, color: ButtonColor.dark, onClick: handleZoomOutBtnClick, isDisabled: scale === 0.5 }),
                    React.createElement("span", { className: "text-basic-white text-paragraph-sm" },
                        scale * 100,
                        "%"),
                    React.createElement(Button, { leftIcon: IconCatalog.plus, isRounded: true, color: ButtonColor.dark, onClick: handleZoomInBtnClick, isDisabled: scale === 2 })),
                showSendButton && React.createElement(Button, { onClick: handlePrimaryAction }, t('general.actions.sendReport'))))));
};
