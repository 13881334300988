import produce from 'immer';
import { create, SetState } from 'zustand';

import { UserPermission, UserPermissionModel } from '../types/UserPermission';

/**
 * User Permissions Store
 */
interface UserPermissionsStore {
  /**
   * Facilities permissions
   */
  facilitiesPermissions: Array<string>;
  /**
   * Modalities permissions
   */
  modalitiesPermissions: Array<string>;
  /**
   * Practitioner permissions
   */
  practitionerPermissions: Array<string>;
  /**
   * Set allowed permissions
   */
  setAllowedPermissions: (permissions: Array<UserPermission>) => void;
}

export const immer = (config) => (set, get) => config((fn) => set(produce(fn)), get);

const getPermissionsByModel = (
  permissions: Array<UserPermission>,
  model: UserPermissionModel,
  key = 'id',
): Array<string> => {
  return permissions.filter((permission) => permission.model === model).map((permission) => permission[key]);
};

const userPermissionsStore = (set: SetState<UserPermissionsStore>): UserPermissionsStore => ({
  facilitiesPermissions: [],
  modalitiesPermissions: [],
  practitionerPermissions: [],
  setAllowedPermissions: (permissions) => {
    set((state) => {
      state.facilitiesPermissions = getPermissionsByModel(permissions, UserPermissionModel.FACILITY);
      state.modalitiesPermissions = getPermissionsByModel(permissions, UserPermissionModel.MODALITY);
      state.practitionerPermissions = getPermissionsByModel(permissions, UserPermissionModel.PRACTITIONER_ASSIGNED);
      return state;
    });
  },
});

export const useUserPermissionStore = create<UserPermissionsStore>(immer(userPermissionsStore));
