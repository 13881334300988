import React from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  StudyStatus,
  UserType,
  InstanceType,
  PatientGender,
  ProductGroupType,
  SeriesType,
  SeriesEcgStatus,
  PractitionerType,
  Maybe,
} from '@eva-pacs/client';
import {
  Modals,
  useModal,
  CurrentModal,
  FileType,
  isAConventionalImage,
  EXTERNAL_KEY,
  MODALITY_TYPES,
} from '@eva-pacs/core';
import { ErrorModalProps } from '@eva-pacs/ui';
import { BurnIso } from '~/components/StudyList/BurnIso';
import { DownloadDicomModal } from '~/components/StudyList/DownloadDicomModal';
import { LATERALITY_TYPE, PRINTING_TEXT_OPTIONS } from '~/constants';
import { DownloadCsv } from '~/components/StudyList/DownloadCsv';
import { PrintDICOM } from '~/components/Viewer/PrintDICOM';
import { UserRole } from '../constants/roleGroups';
import { EVA_PRODUCT } from '../constants/products';
import { DefaultListTabNames } from '../types/StudyList';

/**
 * Get if study is signed or not, depends from his original status
 * @param studyStatus
 * @returns {boolean} validation in study status
 */
export const studyIsSigned = (studyStatus: StudyStatus): boolean => {
  return [StudyStatus.SIGNED, StudyStatus.IN_ADDENDUM, StudyStatus.ADDENDUM_SIGNED, StudyStatus.DELIVERED].includes(
    studyStatus,
  );
};

export const createDownloadZip = ({ studyId, openModal }: { studyId: string; openModal }) => {
  openModal((close) => <DownloadDicomModal studyId={studyId} onClose={close} />);
};

export const createDownloadCsv = ({
  activeTab,
  setCurrentModal,
}: {
  activeTab?: DefaultListTabNames;
  setCurrentModal: React.Dispatch<React.SetStateAction<CurrentModal | null>>;
}) => {
  const closeModal = () => setCurrentModal(null);
  setCurrentModal({
    name: Modals.GenericModal,
    props: {
      isFullWidth: false,
      children: <DownloadCsv activeTab={activeTab} onClose={closeModal} />,
    },
  });
};

export const createDownloadIso = ({
  studyId,
  subtitle,
  setCurrentModal,
  t,
  canBurnDiscs,
}: {
  studyId: string;
  subtitle: string;
  setCurrentModal: React.Dispatch<React.SetStateAction<CurrentModal | null>>;
  t;
  canBurnDiscs: boolean;
}) => {
  const closeModal = () => setCurrentModal(null);
  setCurrentModal({
    name: Modals.GenericModal,
    props: {
      title: `${t('study.dropdownMenu.burn')} CD`,
      className: 'max-w-sm',
      children: <BurnIso studyId={studyId} subtitle={subtitle} onDownload={closeModal} canBurnDiscs={canBurnDiscs} />,
    },
  });
};
// NOTE: CREATE A CUSTOM HOOK TO STOP PROP DRILLING
export const createPrintDICOM = ({
  file,
  studyId,
  setCurrentModal,
  t,
  printerId,
  dicomPrintId,
}: {
  file: File;
  studyId: string;
  setCurrentModal: React.Dispatch<React.SetStateAction<CurrentModal | null>>;
  t: TFunction;
  printerId?: string;
  dicomPrintId?: string;
}) => {
  const closeModal = () => setCurrentModal(null);
  setCurrentModal({
    name: Modals.GenericModal,
    props: {
      title: t('viewer.openPrintersModal.title'),
      className: 'max-w-sm',
      children: (
        <PrintDICOM
          file={file}
          studyId={studyId}
          printerId={printerId}
          printSeriesDicomId={dicomPrintId}
          onClose={closeModal}
          subtitle={t('viewer.openPrintersModal.description')}
        />
      ),
    },
  });
};

/**
 * Get if instance is multi frame
 * NOTE: An instance is multi frame if it has one file and more than 1 dicomNumberOfFrames
 * @param instances
 * @returns {boolean} validation if instance is multi frame
 */
export const isMultiFrame = (instances: InstanceType[]) => {
  if (instances.length === 1 && (instances[0].dicomNumberOfFrames as number) > 1) return true;
  return false;
};

export const getUserRole = (user: UserType | null) => getPacsRole(user?.productGroups as Array<ProductGroupType>);

export const getPacsRole = (productGroups: Array<ProductGroupType>) => {
  const productGroup = productGroups?.find((productGroup) => productGroup.product.slug === EVA_PRODUCT.PACS);
  return (productGroup?.group?.name ?? '') as UserRole;
};

export const getPatientGenderText = (gender: PatientGender, t) => {
  switch (gender) {
    case PatientGender.F:
      return t('admin.formFields.gender.options.female');
    case PatientGender.M:
      return t('admin.formFields.gender.options.male');
    case PatientGender.O:
      return t('admin.formFields.gender.options.other');
    default:
      return '';
  }
};

export const getErrorByGraphqlKey = (
  t,
  errors: { [key: string]: Array<{ code }> } | Array<{ code: string }>,
): string | undefined => {
  if (typeof errors === 'object') {
    const firstErrorKey = Object.keys(errors)[0];
    return t(`errors.graphql.${errors[firstErrorKey][0].code}`);
  } else {
    const firstError: { code: string } = errors[0];
    return t(`errors.graphql.${firstError.code}`);
  }
};
/**
 * Handles errors in app
 * @param {boolean} logMessage set if error is sent to logger
 * @param {{ [key: string]: Array<{ code }> } | Array<{ code: string }>} errors set errors to translate with i18n
 * @param {ErrorModalProps} modalProps set props to show visual feedback with a Modal.
 * @returns {void}
 */

export interface HandleErrorOptions {
  logMessage: string | unknown;
  errors: TranslateErrorCodes;
  modalProps: ErrorModalProps;
}

/*
 * AtLeastOne<T> is Partial<T> intersected with something.
 * U[keyof U] it's the union of all property values of U.
 * Defined the default value of U to be a mapped type where each property of T is mapped to Pick<T, K>, a single-property type for the key K
 */

export type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> & U[keyof U];

type TranslateErrorCodes = { [key: string]: Array<{ code }> } | Array<{ code: string }>;

export const useErrorHandler = () => {
  const { setCurrentModal } = useModal();
  const { t } = useTranslation();

  const handleError = (options: AtLeastOne<HandleErrorOptions>): void => {
    const { modalProps, errors, logMessage } = options;
    const description: string | undefined = modalProps?.description
      ? modalProps?.description
      : errors
      ? getErrorByGraphqlKey(t, errors)
      : undefined;

    if (logMessage) {
      console.error(logMessage);
    }

    if (modalProps) {
      setCurrentModal({
        name: Modals.ErrorModal,
        props: {
          ...options?.modalProps,
          description,
        },
      } as CurrentModal<ErrorModalProps>);
    }
  };
  return { handleError };
};

/**
 * Convert GB to Bytes
 * @param {number} value GB to convert to Bytes
 * @returns {number} Bytes
 */
export const gbToBytes = (value: number): number => value * 1e9;

export const isObjectEmpty = (objectRef) => Object.keys(objectRef).length === 0;

export enum HTMLSpecialCharacters {
  middot = '·',
}

export const getFileType = (fileName: string) => {
  if (fileName.toLocaleLowerCase().includes(`.${FileType.PDF.toLowerCase()}`)) return FileType.PDF;
  if (fileName.toLocaleLowerCase().includes(`.${FileType.STL.toLowerCase()}`)) return FileType.STL;
  if (isAConventionalImage(fileName)) return FileType.CONVENTIONAL_IMAGE;
  if (fileName.toLowerCase().includes(EXTERNAL_KEY.toLowerCase())) return FileType.NO_DICOM;
  return FileType.DICOM;
};

export const getFileTypeFromModality = (
  modality: MODALITY_TYPES,
  hasDocuments?: boolean | null,
): FileType | undefined => {
  if ([MODALITY_TYPES.DOC, MODALITY_TYPES.SR].includes(modality) || hasDocuments) return FileType.DICOM_PDF;
};

export const formatTranslations = (options: { value: string; label: string }, t: TFunction) => ({
  ...options,
  label: t(options.label),
});

export const getOperatorName = (
  serieId: string,
  serieList?: Array<Pick<SeriesType, 'id' | 'dicomOperatorsName'>>,
  radiologistTechnician?: Maybe<
    { __typename?: 'PractitionerType' } & Pick<PractitionerType, 'id' | 'fullName' | 'initials'>
  >,
) => {
  if (radiologistTechnician) return radiologistTechnician.initials;
  if (!serieList) return undefined;

  const serieListIndex = serieList.findIndex((item) => item.id === serieId);
  if (serieListIndex === -1) return undefined;
  return serieList[serieListIndex].dicomOperatorsName;
};

export const getHotKeySequence = (hotKey) => {
  if (hotKey.sequence) return hotKey.sequence;
  return hotKey.sequences;
};

export const getLateralityAlignment = ({
  laterality,
  showPrintView,
  showText,
}: {
  laterality?: LATERALITY_TYPE;
  showPrintView: boolean;
  showText: PRINTING_TEXT_OPTIONS;
}) => {
  if (!showPrintView) return laterality;
  if (showText === PRINTING_TEXT_OPTIONS.RIGHT) return LATERALITY_TYPE.L;
  if (showText === PRINTING_TEXT_OPTIONS.LEFT) return LATERALITY_TYPE.R;
  if (showText === PRINTING_TEXT_OPTIONS.MAMMOGRAPHY) return laterality;
  return laterality;
};

export const filterVisibilityOptions = (value: string, currentSerieModality?: MODALITY_TYPES) => {
  const isMastographyOption = value === PRINTING_TEXT_OPTIONS.MAMMOGRAPHY;
  if (!isMastographyOption) return true;

  const isMgSerie = currentSerieModality === MODALITY_TYPES.MG;
  const isCrSerie = currentSerieModality === MODALITY_TYPES.CR;
  const isMgOrCrSerie = isMgSerie || isCrSerie;
  const isAutoAlignable = isMastographyOption && isMgOrCrSerie;
  if (!isAutoAlignable) return false;

  return isAutoAlignable;
};

export const getECGstatus = (serie) => {
  const isECG = serie?.modality.includes(FileType.ECG);
  if (!isECG) return { isECG: false, isGraphicable: false, isPDFECG: false };

  const statusECG = serie?.series.ecgSeries[0]?.status;
  const isFailedECG = statusECG === SeriesEcgStatus.FAILED;
  const isUndefinedECG = statusECG === undefined;
  const isGraphicable = !isFailedECG && !isUndefinedECG;
  const isPDFECG = serie.series.hasDocument;
  const isImageECG = isECG && !isPDFECG && !isGraphicable;

  return { isECG, isGraphicable, isPDFECG, isImageECG };
};
