/**
 * User Permission Model
 */
export enum UserPermissionModel {
  FACILITY = 'FACILITY',
  MODALITY = 'MODALITY',
  PRACTITIONER_ASSIGNED = 'PRACTITIONER_ASSIGNED',
  FACILITY_EQUITY_ASSIGNATION = 'FACILITY_EQUITY_ASSIGNATION',
}

/**
 * User Permission
 */
export interface UserPermission {
  id: string;
  allowed: boolean;
  model: UserPermissionModel;
}
