import cornerstoneTools from 'cornerstone-tools';
import cornerstone from 'cornerstone-core';
import { ImageLoaderQueue } from '~/utils/ImageLoaderQueue';

const triggerEvent = cornerstoneTools.importInternal('util/triggerEvent');

// Create a shared queue instance
const imageLoaderQueue = new ImageLoaderQueue();

function loadImageWithQueue(newImageId: string, preventCache: boolean): Promise<any> {
  return imageLoaderQueue.enqueue(newImageId, preventCache);
}

/**
 * Scrolls through the stack to the image index requested.
 * @export @public @method
 * @name scrollToIndex
 *
 * @param  {type} element         The element to scroll through.
 * @param  {type} newImageIdIndex The target image index.
 * @returns {void}
 */
export const scrollToIndex = (element, nextImageIdIndex) => {
  const toolData = cornerstoneTools.getToolState(element, 'stack');

  if (!toolData || !toolData.data || !toolData.data.length) {
    return;
  }

  // If we have more than one stack, check if we have a stack renderer defined
  let stackRenderer;

  if (toolData.data.length > 1) {
    const stackRendererData = cornerstoneTools.getToolState(element, 'stackRenderer');

    if (stackRendererData?.data?.length) {
      stackRenderer = stackRendererData.data[0];
    }
  }

  const stackData = toolData.data[0];
  let newImageIdIndex = nextImageIdIndex;
  // Allow for negative indexing
  if (newImageIdIndex < 0) {
    newImageIdIndex += stackData.imageIds.length;
  }

  const startLoadingHandler = cornerstoneTools.loadHandlerManager.getStartLoadHandler(element);
  const endLoadingHandler = cornerstoneTools.loadHandlerManager.getEndLoadHandler(element);
  const errorLoadingHandler = cornerstoneTools.loadHandlerManager.getErrorLoadingHandler(element);

  function doneCallback(image) {
    if (stackData.currentImageIdIndex !== newImageIdIndex) {
      return;
    }

    // Check if the element is still enabled in Cornerstone,
    // If an error is thrown, stop here.
    try {
      cornerstone.getEnabledElement(element);
    } catch (error) {
      return;
    }

    if (stackRenderer) {
      stackRenderer.currentImageIdIndex = newImageIdIndex;
      stackRenderer.render(element, toolData.data);
    } else {
      cornerstone.displayImage(element, image);
    }

    if (endLoadingHandler) {
      endLoadingHandler(element, image);
    }
  }

  function failCallback(error) {
    const imageId = stackData.imageIds[newImageIdIndex];

    if (errorLoadingHandler) {
      errorLoadingHandler(element, imageId, error);
    }
  }

  if (newImageIdIndex === stackData.currentImageIdIndex) {
    return;
  }

  if (startLoadingHandler) {
    startLoadingHandler(element);
  }

  const eventData = {
    newImageIdIndex,
    direction: newImageIdIndex - stackData.currentImageIdIndex,
  };

  stackData.currentImageIdIndex = newImageIdIndex;
  const newImageId = stackData.imageIds[newImageIdIndex];

  // Convert the preventCache value in stack data to a boolean
  const preventCache = Boolean(stackData.preventCache);

  const imagePromise = loadImageWithQueue(newImageId, preventCache);

  imagePromise.then(doneCallback, failCallback);
  // Make sure we kick off any changed download request pools
  cornerstoneTools.requestPoolManager.startGrabbing();
  triggerEvent(element, cornerstoneTools.EVENTS.STACK_SCROLL, eventData);
};
