import cornerstone from 'cornerstone-core';

/**
 * A queue for loading images with a maximum number of concurrent requests.
 *
 * @returns {Object|null} - Returns an object with petSerieId and ctSerieId if fusion is possible, otherwise null.
 * @author Mauricio Campos <mauricio.campos@evacenter.com>
 */
export class ImageLoaderQueue {
  private queue: Array<{ imageId: string; resolve: any; reject: any }> = [];
  private activeRequests = 0;
  private maxConcurrentRequests = 5;

  /**
   * Sets the maximum number of concurrent requests.
   * @param maxConcurrentRequests The maximum number of concurrent requests.
   * @public
   */
  setMaxConcurrentRequests(maxConcurrentRequests: number) {
    this.maxConcurrentRequests = maxConcurrentRequests;
  }

  /**
   * Adds an image request to the queue with high priority (LIFO order).
   * @param imageId The ID of the image to load.
   * @param preventCache Whether to bypass the cache.
   * @returns A promise that resolves when the image is loaded.
   */
  enqueue(imageId: string, preventCache: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      // Add new request at the beginning of the queue (LIFO priority)
      this.queue.unshift({ imageId, resolve, reject });
      this.processQueue(preventCache);
    });
  }

  /**
   * Processes the queue, ensuring a maximum of `maxConcurrentRequests` active requests.
   * @param preventCache Whether to bypass the cache.
   */
  private async processQueue(preventCache: boolean) {
    if (this.activeRequests >= this.maxConcurrentRequests || this.queue.length === 0) {
      return;
    }

    this.activeRequests++;
    const { imageId, resolve, reject } = this.queue.shift()!;

    try {
      const imagePromise = preventCache ? cornerstone.loadImage(imageId) : cornerstone.loadAndCacheImage(imageId);
      const image = await imagePromise;
      resolve(image);
    } catch (error) {
      reject(error);
    } finally {
      this.activeRequests--;
      this.processQueue(preventCache); // Process next request
    }
  }
}
