import { getMediaUrl, UploadFileError } from '@eva-pacs/core';
import { StudyStatus } from '../graphql';
/**
 * Get list of images to preview from a specific study object
 * @param {StudyType} study Study object to get images
 * @returns {Array<{ id: number; imageUrl: string; title: string }>} List of the first image of each series
 */
export var getStudyPreviewImages = function (study) {
    if (!(study && study.series))
        return [];
    return removeEmptySeries(study.series).map(function (serie, index) {
        return {
            id: index,
            imageUrl: getThumbnail(serie.instances),
            title: serie.modality.identifier,
        };
    });
};
/**
 * Get list of modalities from a specific study object
 * @param {StudyType} study Study object to get images
 * @returns {{list: Array<string>, text: string}} Object with text format or array format
 */
export var getStudyModalityList = function (study) {
    var modalities = study.series
        .map(function (serie) { return serie.modality.identifier; })
        .reduce(function (previousArray, currentElement) {
        if (previousArray.indexOf(currentElement) < 0)
            previousArray.push(currentElement);
        return previousArray;
    }, []);
    return {
        inArrayType: modalities,
        inStringType: modalities.toString(),
    };
};
/**
 * Get text of study status from i18n
 * @param {TFunction<"translation">} t Translate function
 * @param {StudyStatus} studyStatus Key of study status to translate
 * @returns {string} Readable text of study status
 */
export var getStudyStatusText = function (t, studyStatus) {
    var _a;
    var studyStatusKeys = (_a = {},
        _a[StudyStatus.ADDENDUM_SIGNED] = 'addendumSigned',
        _a[StudyStatus.DELIVERED] = 'delivered',
        _a[StudyStatus.IN_ADDENDUM] = 'inAddendum',
        _a[StudyStatus.READING] = 'reading',
        _a[StudyStatus.READING_PENDING] = 'readingPending',
        _a[StudyStatus.SIGNED] = 'signed',
        _a[StudyStatus.UPLOADING] = 'uploading',
        _a[StudyStatus.IMAGES_SENT] = 'imagesSent',
        _a[StudyStatus.REVIEW_PENDING] = 'reviewPending',
        _a[StudyStatus.RECALLED] = 'recalled',
        _a[StudyStatus.RECALL_PENDING] = 'recallPending',
        _a[StudyStatus.RECALL_REQUESTED] = 'recallRequested',
        _a);
    return t("study.status.".concat(studyStatusKeys[studyStatus]));
};
/**
 * Get text of upload error from i18n
 * @param {TFunction<"translation">} t Translate function
 * @param {UploadFileError} errorCode Key of error code to translate
 * @returns {string} Readable text of error code
 */
export var getUploadErrorText = function (t, errorCode, file) {
    var _a;
    var errorCodeKeys = (_a = {},
        _a[UploadFileError.UNSUPPORTED_MEDIA_TYPE] = 'unsupportedMediaType',
        _a[UploadFileError.UNEXPECTED_ERROR] = 'unexpectedError',
        _a[UploadFileError.FILE_ALREADY_EXISTS] = 'fileAlreadyExists',
        _a);
    return t("errors.uploader.".concat(errorCodeKeys[errorCode]), { file: file });
};
export var removeEmptySeries = function (series) {
    if (!series)
        return [];
    return series.filter(function (serie) { var _a; return ((_a = serie === null || serie === void 0 ? void 0 : serie.instances) === null || _a === void 0 ? void 0 : _a.length) > 0; });
};
export var getThumbnail = function (instances) {
    var _a, _b, _c;
    var instancePreview = instances.find(function (instance) { return instance.isPreview; });
    if (!instancePreview)
        instancePreview = instances.find(function (instance) { var _a; return (_a = instance.files) === null || _a === void 0 ? void 0 : _a.imageUrl; });
    if (!instancePreview)
        instancePreview = instances.find(function (instance) { var _a; return (_a = instance.files) === null || _a === void 0 ? void 0 : _a.imageUrl; });
    if (!instancePreview)
        instancePreview = { files: { imageUrl: '', image: '' } };
    return "".concat(getMediaUrl((_b = (_a = instancePreview.files) === null || _a === void 0 ? void 0 : _a.imageUrl) !== null && _b !== void 0 ? _b : '', (_c = instancePreview.files) === null || _c === void 0 ? void 0 : _c.image));
};
