import cornerstoneMath from 'cornerstone-math';
import cornerstone from 'cornerstone-core';
import csTools from 'cornerstone-tools';

import { AiColorSolid, PIXELS_NEAR_TOOL, PIXELS_NEAR_TOOL_TOUCH } from '~/constants';
import { ToolName } from '~/src/constants/toolName';
import { useMeasurementStore } from '~/src/store';

import {
  _organizePositions,
  _drawFindingTextBox,
  _findTextBoxAnchorPoints,
  _getFindingBoxTextCoords,
  _moveHandleNearImagePoint,
} from './helpers/findingToolHelpers';

const getNewContext = csTools.importInternal('drawing/getNewContext');
const setShadow = csTools.importInternal('drawing/setShadow');
const drawRect = csTools.importInternal('drawing/drawRect');
const draw = csTools.importInternal('drawing/draw');

/**
 * @public
 * @class FindingBox
 * @memberof Tools.Annotation
 * @classdesc Tool for drawing rectangular regions of interest, and measuring
 * the statistics of the enclosed pixels.
 * @extends Tools.Annotation.FindingBoxTool
 * @author Ricardo Aguirre <ricardo.a.nava@gmail.com>
 * Created at 2022-03-16
 */
export default class CustomFindingBox extends csTools.RectangleRoiTool {
  name = ToolName.FindingBox;
  constructor(props) {
    super(props);
    this.name = ToolName.FindingBox;
  }

  pointNearTool(element, data, coords, interactionType) {
    const hasStartAndEndHandles = data && data.handles && data.handles.start && data.handles.end;
    const validParameters = hasStartAndEndHandles;

    if (!validParameters) csTools.logger.warn(`invalid parameters supplied to tool ${this.name}'s pointNearTool`);
    if (!validParameters || data.visible === false) return false;

    const distance = interactionType === 'mouse' ? PIXELS_NEAR_TOOL : PIXELS_NEAR_TOOL_TOUCH;
    const startCanvas = cornerstone.pixelToCanvas(element, data.handles.start);
    const endCanvas = cornerstone.pixelToCanvas(element, data.handles.end);

    const rect = {
      left: Math.min(startCanvas.x, endCanvas.x),
      top: Math.min(startCanvas.y, endCanvas.y),
      width: Math.abs(startCanvas.x - endCanvas.x),
      height: Math.abs(startCanvas.y - endCanvas.y),
    };

    const distanceToPoint = cornerstoneMath.rect.distanceToPoint(rect, coords);

    return distanceToPoint < distance;
  }

  renderToolData(evt) {
    const toolData = csTools.getToolState(evt.currentTarget, this.name);
    if (!toolData) return;

    const eventData = evt.detail;
    const { image, element } = eventData;
    const lineWidth = csTools.toolStyle.getToolWidth();
    const lineDash = csTools.getModule('globalConfiguration').configuration.lineDash;
    // @ts-ignore
    const { renderDashed } = this.configuration;
    const context = getNewContext(eventData.canvasContext.canvas);

    draw(context, (context) => {
      // If we have tool data for this element - iterate over each set and draw it
      for (let index = 0; index < toolData.data.length; index++) {
        const unnullableIndex = index;
        const data = toolData.data[index];
        const hasAiInfo = data?.automatic_bounding_box?.length;

        if (data.visible === false || !hasAiInfo) continue;

        // Configure
        const color = AiColorSolid;

        // @ts-ignore
        setShadow(context, this.configuration);

        const rectOptions = { color, lineDash: undefined };

        if (renderDashed) rectOptions.lineDash = lineDash;

        // Draw
        drawRect(
          context,
          element,
          data.handles.start,
          data.handles.end,
          rectOptions,
          'pixel',
          data.handles.initialRotation,
        );

        const invalidated = data.invalidated;
        if (invalidated) {
          if (data.cachedStats) {
            // @ts-ignore
            this.throttledUpdateCachedStats(image, element, data);
          } else {
            // @ts-ignore
            this.updateCachedStats(image, element, data);
          }
        }
        const hasMoved = data.handles.textBox.hasMoved;
        if (!hasMoved) {
          const defaultCoords = _getFindingBoxTextCoords(eventData.viewport, data.handles, unnullableIndex);
          const nextTextCoords = toolData.data
            .map((next, nextIndex) => _getFindingBoxTextCoords(eventData.viewport, next.handles, nextIndex))
            .filter((_, nextIndex) => nextIndex !== unnullableIndex);

          const organizedCoords = _organizePositions([defaultCoords, ...nextTextCoords]);
          const currentCoord = organizedCoords.find((element) => element.index === index);
          Object.assign(data.handles.textBox, currentCoord);
        }
        const measureName =
          useMeasurementStore.getState().getMeasurementById(data.uuid)?.finding?.medicalCondition?.name ||
          data?.automatic_bounding_box;
        const textBoxAnchorPoints = (handles) => _findTextBoxAnchorPoints(handles.start, handles.end);
        const textBoxContent = [measureName];

        _drawFindingTextBox(
          context,
          element,
          data.handles.textBox,
          textBoxContent,
          data.handles,
          textBoxAnchorPoints,
          color,
          lineWidth,
          10,
          true,
        );
      }
    });
  }

  toolSelectedCallback() {
    return;
  }

  handleSelectedCallback(evt, toolData, handle, interactionType = 'mouse') {
    if (!handle?.drawnIndependently) return;
    _moveHandleNearImagePoint(evt, this, toolData, handle, interactionType);
  }
}
