import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';
import { useTranslation } from 'react-i18next';
import { ToastPropsV2, ToastVariant, useToast } from '@evacenter/eden';

import {
  StudyStatus,
  useDeleteStudyMutation,
  useUpdateStudyMutation,
  useUndoSignReportMutation,
  useRequireReviewReportMutation,
  useUpdateSealStudyMutation,
} from '@eva-pacs/client';
import { Modals, useModal as useModalLegacy } from '@eva-pacs/core';

import { Study } from '~/src/types/Study';
import { EVACENTER_URL } from '~/constants';
import { useStudyListStore } from '~/src/store';
import { useStudyActions } from './useStudyActions';
import { FeatureFlags } from '~/src/constants/featureFlags';
import { useFeatureFlagsStore } from '~/src/store/flagsStore';
import { PreviewFileModal } from '~/components/shared/PreviewFileModal';
import { PreviewPDFWrapper } from '~/components/shared/PreviewPDFWrapper';
import { PreviewQRStudyModal } from '~/components/StudyList/PreviewQRStudyModal';
import { createDownloadZip, createDownloadIso, useErrorHandler } from '~/utils/appHelpers';
import { StudyAndPatientFormModal } from '~/components/StudyList/StudyEditModal/StudyAndPatientFormModal';
import { PreviewPDFWithoutSignatureWrapper } from '~/components/shared/PreviewPDFWithoutSignatureWrapper';

enum ErrorCodes {
  SIGNED_STUDY_EXCEPTION = 'signed_study_exception',
}

export const useStudyOptionMenuActions = () => {
  const { setCurrentModal } = useModalLegacy();
  const { t } = useTranslation();
  const { performUpdateStudy } = useStudyActions();
  const { addToast } = useToast();
  const { handleError } = useErrorHandler();
  const [studies, getStudy] = useStudyListStore((state) => [state.studies, state.getStudy], shallow);
  const hasFlag = useFeatureFlagsStore((state) => state.hasFlag);
  const [updateStudyMutation] = useUpdateStudyMutation();
  const [requireReviewReportMutation] = useRequireReviewReportMutation();

  const closeModal = () => setCurrentModal(null);

  const [deleteStudyMutation] = useDeleteStudyMutation({
    onCompleted: ({ deleteStudy: { success, errors } }) => {
      if (success) {
        closeModal();
      } else {
        handleError({
          modalProps: {
            onClose: closeModal,
          },
          errors,
        });
      }
    },
  });

  const [updateSealStudy] = useUpdateSealStudyMutation();

  const [undoSignReportMutation] = useUndoSignReportMutation({
    onCompleted: (data) => {
      if (data.undoSignReport.success === false) {
        handleError({ modalProps: { onClose: closeModal }, errors: data.undoSignReport.errors });
        return;
      }
    },
  });

  const handleCloseEditModal = useCallback(() => {
    handleDefaultCancelModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const performDeleteStudyAction = useCallback((studyId: string) => {
    const handleConfirmDeleteStudy = () =>
      deleteStudyMutation({
        variables: {
          id: studyId,
        },
      });

    setCurrentModal({
      name: Modals.ConfirmationModal,
      props: {
        title: t('general.modal.deleteStudy.title'),
        description: t('general.modal.deleteStudy.description'),
        onAccept: handleConfirmDeleteStudy,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDefaultCancelModal = useCallback(() => {
    setCurrentModal(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const performEditStudyAction = useCallback((studyId: string) => {
    const showNewStudyEditWebappsPage = hasFlag(FeatureFlags.ShowNewStudyEditWebappsPage);

    if (showNewStudyEditWebappsPage) {
      const syncedApp = window.open(`${EVACENTER_URL}/pacs/study-edit/${studyId}`, '_blank');
      window.syncedApp = syncedApp;
      return;
    }

    setCurrentModal({
      name: Modals.GenericModal,
      props: {
        title: t('viewer.report.editInfoBtn'),
        className: 'max-w-5xl',
        isMaxWidthCustom: true,
        children: (
          <StudyAndPatientFormModal
            studyId={studyId}
            onCloseModal={handleCloseEditModal}
            onDefaultCancel={handleDefaultCancelModal}
          />
        ),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const performReportViewAction = useCallback((studyId: string) => {
    setCurrentModal({
      name: Modals.GenericModal,
      props: {
        title: t('general.modal.seeReport'),
        isMaxWidthCustom: true,
        isFullWidth: false,
        onClose: (): void => {
          setCurrentModal(null);
        },
        children: <PreviewPDFWrapper studyId={studyId} />,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const performUndoSignReport = async (studyId: string, reportId: string, onSuccess?: () => void) => {
    const study = getStudy(studyId);
    const { data } = await undoSignReportMutation({
      variables: {
        id: reportId,
      },
    });

    const newStudy = {
      ...study,
      studyStatus: StudyStatus.READING,
    };

    if (data?.undoSignReport.success) {
      performUpdateStudy(studyId, newStudy);
      onSuccess?.();
      return;
    }
  };

  const performQRViewAction = useCallback((studyId: string) => {
    setCurrentModal({
      name: Modals.GenericModal,
      props: {
        title: t('general.modal.seeQR.title'),
        className: 'max-w-sm',
        isMaxWidthCustom: true,
        children: <PreviewQRStudyModal studyId={studyId} />,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const performCreateDownloadZipAction = useCallback(
    (studyId: string, openModal) => createDownloadZip({ studyId, openModal }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const performBurnIsoAction = useCallback((studyId: string, canBurnDiscs: boolean) => {
    const study = studies[studyId];
    createDownloadIso({ studyId, subtitle: study?.patient?.fullName as string, setCurrentModal, t, canBurnDiscs });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const performPreviewAction = useCallback((studyId: string) => {
    setCurrentModal({
      name: Modals.GenericModal,
      props: {
        title: t('general.modal.seeImages'),
        children: <PreviewFileModal studyId={studyId} />,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const performWatchFilesAction = useCallback((studyId: string) => {
    const evacenterUrl = process.env['REACT_APP_EVACENTER_URL'];
    window.open(`${evacenterUrl}/pacs/study-files/${studyId}`, '_blank');
  }, []);

  const performToggleDoubleSignAction = async (studyId: string) => {
    const study = getStudy(studyId);
    const isDoubleSignEnabled = study.reviewRequired;
    const toastMessage = isDoubleSignEnabled
      ? // eslint-disable-next-line i18next/no-literal-string
        'study.studyTable.tableActions.doubleSignDisabled'
      : // eslint-disable-next-line i18next/no-literal-string
        'study.studyTable.tableActions.doubleSignEnabled';
    try {
      const { data } = await updateStudyMutation({
        variables: {
          id: studyId,
          input: {
            reviewRequired: !isDoubleSignEnabled,
          },
        },
      });

      const newStudy: Study = {
        ...study,
        reviewRequired: !isDoubleSignEnabled,
        studyStatus: data?.updateStudy.study?.status,
      };

      performUpdateStudy(studyId, newStudy);
      // TODO[Chava]: Review global message with product: https://eva-pacs.atlassian.net/browse/EVA-5025
      if (!data?.updateStudy.success) {
        const error = data?.updateStudy.errors?.system[0];

        if (error.code === ErrorCodes.SIGNED_STUDY_EXCEPTION) {
          performUpdateStudy(studyId, study);
          return addToast({
            variant: ToastVariant.error,
            title: t('study.studyTable.tableActions.signedStudyInDoubleSignError'),
            dismissInterval: 5000,
          } as ToastPropsV2);
        }

        throw new Error(data?.updateStudy.errors);
      }

      addToast({
        variant: ToastVariant.success,
        title: t(toastMessage),
        dismissInterval: 5000,
      } as ToastPropsV2);
      return data;
    } catch (error) {
      performUpdateStudy(studyId, study);
      handleError({ logMessage: error });
    }
  };

  const performSealStudy = async (studyId: string) => {
    const study = getStudy(studyId);
    try {
      const newStudy: Study = {
        ...study,
        isSealed: true,
      };

      performUpdateStudy(studyId, newStudy);

      const { data } = await updateSealStudy({
        variables: {
          id: studyId,
        },
      });
      if (!data?.sealStudy.success) throw new Error(data?.sealStudy.errors);

      if (!data?.sealStudy.study?.isSealed) throw new Error();

      addToast({
        variant: ToastVariant.success,
        title: t('study.studyTable.tableActions.sealStudy.sealSuccess'),
        dismissInterval: 5000,
      } as ToastPropsV2);
      return data;
    } catch (error) {
      performUpdateStudy(studyId, study);
      handleError({ logMessage: error });
      addToast({
        variant: ToastVariant.error,
        title: t('errors.sealStudy.genericError'),
        dismissInterval: 5000,
      } as ToastPropsV2);
    }
  };

  const performRequireReviewReportAction = async (studyId: string, reportId?: string, onSuccess?: () => void) => {
    const study = getStudy(studyId);
    try {
      const newStudy: Study = {
        ...study,
        studyStatus: StudyStatus.REVIEW_PENDING,
      };
      performUpdateStudy(studyId, newStudy);
      const { data } = await requireReviewReportMutation({
        variables: {
          id: reportId,
        },
      });

      if (!data?.requireReviewReport.success) throw new Error(data?.requireReviewReport.errors);

      if (onSuccess) onSuccess();

      return data;
    } catch (error) {
      performUpdateStudy(studyId, study);
      handleError({ logMessage: error });
    }
  };

  const performPreviewReport = (studyId: string) => {
    setCurrentModal({
      name: Modals.GenericModal,
      props: {
        title: t('general.modal.seePreview'),
        isFullWidth: false,
        onClose: (): void => setCurrentModal(null),
        children: <PreviewPDFWithoutSignatureWrapper studyId={studyId} />,
      },
    });
  };

  return {
    performBurnIsoAction,
    performCreateDownloadZipAction,
    performPreviewAction,
    performRequireReviewReportAction,
    performUndoSignReport,
    performQRViewAction,
    performToggleDoubleSignAction,
    performSealStudy,
    performReportViewAction,
    performWatchFilesAction,
    performEditStudyAction,
    performDeleteStudyAction,
    performPreviewReport,
  };
};
