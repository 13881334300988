import { getMappedColor } from './getMappedColor';

const DEFAULT_HEATMAP_OPACITY = 0.6;
const DEFAULT_UNIT_SIZE = 4;

interface DrawHeatmapOptions {
  opacity: number;
  blockSize?: number;
}

/**
 * Draws a heatmap on a given canvas context based on a 2D array of values.
 *
 * @param canvasContext - The canvas rendering context where the heatmap will be drawn.
 * @param heatMap - A 2D array representing the heatmap values. Each value corresponds to an intensity level.
 * @param options - Configuration options for the heatmap rendering.
 * @param options.opacity - The opacity level of the heatmap (default is `DEFAULT_HEATMAP_OPACITY`).
 * @param options.blockSize - The size of each heatmap unit (default is `DEFAULT_UNIT_SIZE`).
 */

export const drawHeatmap = (
  canvasContext: CanvasRenderingContext2D,
  headMap: Array<Array<number>>,
  options: DrawHeatmapOptions,
) => {
  canvasContext.save();
  if (!headMap.length) return;

  const mapWidth = headMap[0].length;
  const mapHeight = headMap.length;

  canvasContext.globalAlpha = options.opacity ?? DEFAULT_HEATMAP_OPACITY;
  const blockSize = options.blockSize ?? DEFAULT_UNIT_SIZE;

  for (let x = 0; x < mapHeight; x += blockSize) {
    for (let y = 0; y < mapWidth; y += blockSize) {
      const mapValue = headMap[x][y];
      if (mapValue) {
        const color = getMappedColor(mapValue);
        canvasContext.fillStyle = color;
        canvasContext.fillRect(y, x, blockSize, blockSize);
      }
    }
  }
  canvasContext.restore();
};
