import { useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import { Subscription, useStudiesUpdateSubscription, useStudyCriticalValuesSubscription } from '@eva-pacs/client';

import { FeatureFlags } from '~/src/constants/featureFlags';
import { useFeatureFlagsStore } from '~/src/store/flagsStore';
import { useSessionStore, useUserPermissionStore } from '~/src/store';
import { UpdateEvent } from '~/src/hooks/worklist/useWorklistUpdates';

import { useStudyUpdatesOrchestrator } from './useStudyUpdatesOrchestrator';
import { NotificationType, useNotificationsStore } from '../../store/notifications-store';

export enum StudyUpdateType {
  created = 'created',
  updated = 'updated',
  error = 'error',
}

export type StudyUpdateEvent = Record<string, StudyUpdateType>;

type StudiesUpdateSubscriptionEventType = {
  type: string;
  study_id: string;
  practitioner_assigned_id: string;
  old_practitioner_assigned_id: string;
  status: string;
  old_status: string;
  created_at: string;
  reviewer_practitioner_id: string;
  old_reviewer_practitioner_id: string;
  referring_practitioner_id: string;
  facility_id: string;
};

type UseStudiesChangesHook = {
  studiesCreated: number;
};

type StudyCriticalValuesSubscriptionEventType = {
  id: string;
  study_id: string;
  deleted: boolean;
  observation: string;
  critical_value_id: string;
  critical_value_name: string;
};

export const useStudyListUpdates = (): UseStudiesChangesHook => {
  const hasFlag = useFeatureFlagsStore((state) => state.hasFlag);
  const { processStudy, processCriticalValue } = useStudyUpdatesOrchestrator();
  const [currentOrganizationId, currentAccount] = useSessionStore(
    (state) => [state.currentOrganizationId, state.getCurrentAccount],
    shallow,
  );
  const [getNotifications, setNotifications] = useNotificationsStore(
    (state) => [state.getNotifications, state.setNotifications],
    shallow,
  );
  const [facilitiesPermissions, modalitiesPermissions, practitionerPermissions] = useUserPermissionStore(
    (state) => [state.facilitiesPermissions, state.modalitiesPermissions, state.practitionerPermissions],
    shallow,
  );

  const practitionerId = currentAccount()?.practitionerId;
  const WS_IS_ACTIVE = process.env.REACT_APP_WS_IS_ACTIVE === 'true';
  const enableAutomaticWorklistUpdates = hasFlag(FeatureFlags.EnableAutomaticUpdatesWorklist);
  const studyListUpdatesNotifications = getNotifications(NotificationType.StudyListUpdates) as StudyUpdateEvent;

  const studiesCreated = useMemo(() => {
    return Object.keys(studyListUpdatesNotifications).filter(
      (studyId) => studyListUpdatesNotifications?.[studyId] === StudyUpdateType.created,
    ).length;
  }, [studyListUpdatesNotifications]);

  const handleShowNotification = (studyId: string) => {
    setNotifications<StudyUpdateEvent>({
      type: NotificationType.StudyListUpdates,
      payload: {
        [studyId]: StudyUpdateType.created,
      },
    });
  };

  useStudiesUpdateSubscription({
    skip: !WS_IS_ACTIVE,
    variables: {
      organizationId: currentOrganizationId,
    },
    onSubscriptionData: async ({ subscriptionData }) => {
      const { studiesUpdate } = (subscriptionData?.data ?? {}) as Pick<Subscription, 'studiesUpdate'>;
      const {
        type: eventType,
        study_id: studyId,
        practitioner_assigned_id: studyPractitionerId,
        old_practitioner_assigned_id: studyOldPractitionerId,
        status,
        old_status: oldStatus,
        created_at: createdAt,
        reviewer_practitioner_id: reviewerPractitionerId,
        old_reviewer_practitioner_id: oldReviewerPractitionerId,
        referring_practitioner_id: referringPractitionerId,
        facility_id: facilityId,
      } = JSON.parse(studiesUpdate?.event ?? {}) as StudiesUpdateSubscriptionEventType;

      // only process updates when FF is enabled.
      if (enableAutomaticWorklistUpdates && eventType) {
        processStudy({
          studyPractitionerId,
          studyId,
          eventType: eventType as UpdateEvent,
          studyOldPractitionerId,
          userId: practitionerId,
          reviewerPractitionerId,
          oldReviewerPractitionerId,
          status,
          oldStatus,
          createdAt,
        });
      }

      // for study list.
      if (eventType === StudyUpdateType.created) {
        if (referringPractitionerId && practitionerId && referringPractitionerId === practitionerId) {
          handleShowNotification(studyId);
          return;
        }

        const userHasStudies = studyPractitionerId === practitionerId || reviewerPractitionerId === practitionerId;

        if (
          userHasStudies ||
          facilitiesPermissions.includes(facilityId) ||
          modalitiesPermissions.includes(facilityId) ||
          practitionerPermissions.includes(studyPractitionerId)
        ) {
          handleShowNotification(studyId);
          return;
        }
      }
    },
  });

  useStudyCriticalValuesSubscription({
    skip: !WS_IS_ACTIVE,
    variables: {
      organizationId: currentOrganizationId,
    },
    onSubscriptionData: async ({ subscriptionData }) => {
      const { studyCriticalValues } = (subscriptionData?.data ?? {}) as Pick<Subscription, 'studyCriticalValues'>;
      const {
        id,
        deleted,
        observation,
        study_id: studyId,
        critical_value_id: criticalValueId,
        critical_value_name: criticalValueName,
      } = JSON.parse(studyCriticalValues?.event ?? {}) as StudyCriticalValuesSubscriptionEventType;

      if (enableAutomaticWorklistUpdates) {
        processCriticalValue({
          id,
          studyId,
          deleted,
          observation,
          criticalValueId,
          criticalValueName,
        });
      }
    },
  });

  return {
    studiesCreated,
  };
};
