import { ReportBirads, StudyStatus, StudyUrgencyLevel } from '@eva-pacs/client';

export enum SLA_STATUS {
  DUE = 'DUE',
  OVERDUE = 'OVERDUE',
  SIGNED = 'SIGNED',
  SIGNED_OVERDUE = 'SIGNED_OVERDUE',
}

export const URGENCY_OPTIONS = [
  { label: 'study.urgencyLevel.normal', value: StudyUrgencyLevel.NORMAL },
  { label: 'study.urgencyLevel.priority', value: StudyUrgencyLevel.PRIORITY },
  { label: 'study.urgencyLevel.urgent', value: StudyUrgencyLevel.URGENT },
];

export const SLA_STATUS_OPTIONS = [
  { label: 'study.slaStatus.due', value: SLA_STATUS.DUE },
  { label: 'study.slaStatus.overdue', value: SLA_STATUS.OVERDUE },
  { label: 'study.slaStatus.signed', value: SLA_STATUS.SIGNED },
  { label: 'study.slaStatus.signedOverdue', value: SLA_STATUS.SIGNED_OVERDUE },
];

export const SEAL_STATUS_OPTIONS = [
  { label: 'study.studyTable.seal.sealed', value: true },
  { label: 'study.studyTable.seal.unSealed', value: false },
];

export const STATUS_OPTIONS = [
  { value: StudyStatus.READING },
  { value: StudyStatus.READING_PENDING },
  { value: StudyStatus.IN_ADDENDUM },
  { value: StudyStatus.SIGNED },
  { value: StudyStatus.ADDENDUM_SIGNED },
  { value: StudyStatus.DELIVERED },
  { value: StudyStatus.UPLOADING },
  { value: StudyStatus.IMAGES_SENT },
  { value: StudyStatus.REVIEW_PENDING },
];

export const RECALL_STATUS_OPTIONS = [
  { value: StudyStatus.RECALLED },
  { value: StudyStatus.RECALL_PENDING },
  { value: StudyStatus.RECALL_REQUESTED },
];

export const BIRADS_OPTIONS = [
  { value: ReportBirads.BIRADS_0 },
  { value: ReportBirads.BIRADS_1 },
  { value: ReportBirads.BIRADS_2 },
  { value: ReportBirads.BIRADS_3 },
  { value: ReportBirads.BIRADS_4 },
  { value: ReportBirads.BIRADS_4A },
  { value: ReportBirads.BIRADS_4B },
  { value: ReportBirads.BIRADS_4C },
  { value: ReportBirads.BIRADS_5 },
  { value: ReportBirads.BIRADS_6 },
  { value: ReportBirads.INADEQUATE },
];

export const PIRADS_OPTIONS = [
  { value: ReportBirads.PIRADS_1 },
  { value: ReportBirads.PIRADS_2 },
  { value: ReportBirads.PIRADS_3 },
  { value: ReportBirads.PIRADS_4 },
  { value: ReportBirads.PIRADS_5 },
];
