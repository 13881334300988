/*
 * @deprecated
 * We need to separate the logic based on responsibilities
 */
import { AxiosResponse } from 'axios';
import {
  ReportType,
  restClient,
  useBuildPdfReportMutation,
  useCancelAddendumMutation,
  useClearReportMutation,
  useCreateAddendumMutation,
  useUpdateAddendumMutation,
  useSignAddendumMutation,
  useUpdateReportFieldMutation,
  CreateAddendumMutation,
  CancelAddendumMutation,
  SignAddendumMutation,
  UpdateReportFieldMutation,
  UpdateAddendumMutation,
  ENDPOINT_PATH,
  UpdateAddendumFieldMutation,
  useUpdateAddendumFieldMutation,
} from '@eva-pacs/client';
import { useErrorHandler } from '~/utils/appHelpers';
import { getAuthHeaders } from '~/utils/getHeadersAuth';
import { MutableRefObject } from 'react';
import { FileRowType } from '@evacenter/eden';

export type BuildPdfReport = Pick<ReportType, 'id' | 'pdfFile'> | undefined;
export interface UploadReportPDFProps {
  tempId?: string;
  file: File;
  fileRef?: MutableRefObject<FileRowType | undefined>;
  errorMsg?: string;
  reportId: string;
}

export interface ReportService {
  isReportUpdating: boolean;
  activeReport: (reports: Array<BuildPdfReport>) => BuildPdfReport | undefined;
  clearReport: (reportId: string) => void;
  rebuildActiveReportPDF: (reports: Array<BuildPdfReport>) => void;
  uploadReportPDF: (props: UploadReportPDFProps) => Promise<AxiosResponse<any>>;
  uploadImageFile: (
    file: File,
    id: string,
    type: 'report_field' | 'addendum',
    endppoint: string,
  ) => Promise<AxiosResponse<any>>;
  createAddendum: (reportId: string, templateId: string) => Promise<CreateAddendumMutation | null | undefined>;
  updateAddendum: (addendumId: string, templateId: string) => Promise<UpdateAddendumMutation | null | undefined>;
  signAddendum: (addendumId: string) => Promise<SignAddendumMutation | null | undefined>;
  cancelAddendum: (addendumId: string) => Promise<CancelAddendumMutation | null | undefined>;
  updateReportField: (id: string, value: string) => Promise<UpdateReportFieldMutation | null | undefined>;
  updateAddendumField: (
    addendumFieldId: string,
    value: string,
  ) => Promise<UpdateAddendumFieldMutation | null | undefined>;
}

export const useReportService = (): ReportService => {
  const { handleError } = useErrorHandler();

  // Apollo hooks
  const [buildPdfReportMutation] = useBuildPdfReportMutation({
    onError: (error) => handleError({ logMessage: error }),
  });

  const [clearReportMutation] = useClearReportMutation({ onError: (error) => handleError({ logMessage: error }) });

  const [updateReportFieldMutation, { loading: isReportUpdating }] = useUpdateReportFieldMutation({
    onError: (error) => handleError({ logMessage: error }),
  });

  const [createAddendumMutation] = useCreateAddendumMutation({
    onError: (error) => handleError({ logMessage: error }),
  });
  const [updateAddendumMutation] = useUpdateAddendumMutation({
    onError: (error) => handleError({ logMessage: error }),
  });
  const [signAddendumMutation] = useSignAddendumMutation({
    onCompleted: ({ signAddendum: { success, errors } }) => {
      if (success === false) handleError({ logMessage: errors });
    },
    onError: (error) => handleError({ logMessage: error }),
  });
  const [cancelAddendumMutation] = useCancelAddendumMutation({
    onError: (error) => handleError({ logMessage: error }),
  });
  const [updateAddendumFieldMutation] = useUpdateAddendumFieldMutation({
    onError: (error) => handleError({ logMessage: error }),
  });

  // Service actions
  const activeReport = (reports: Array<BuildPdfReport>): BuildPdfReport | undefined => reports[0];

  const clearReport = async (reportId: string) => {
    await clearReportMutation({
      variables: {
        id: reportId,
      },
    });
  };

  const rebuildActiveReportPDF = (reports: Array<BuildPdfReport>): void => {
    const report = activeReport(reports ?? []);
    const canRebuildPDF = Boolean(report?.id) && Boolean(report?.pdfFile);

    if (canRebuildPDF) buildPdfReportMutation({ variables: { id: report?.id } });
  };

  const MAX_COMPLETED_PERCENTAGE = 100;

  const handleUploadProgress = (fileRef?: MutableRefObject<FileRowType | undefined>) => (progressEvent) => {
    if (!progressEvent.total || !fileRef?.current) return;
    const percentCompleted = Math.round((progressEvent.loaded * MAX_COMPLETED_PERCENTAGE) / progressEvent.total);

    fileRef.current.uploadProgress = percentCompleted;
    return fileRef;
  };

  const uploadReportPDF = ({ file, fileRef, reportId }: UploadReportPDFProps) => {
    const formData = new FormData();
    formData.append('pdf_file', file);

    return restClient
      .patch(`${ENDPOINT_PATH.REPORTS_FILES}/${reportId}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...getAuthHeaders(),
        },
        onUploadProgress: handleUploadProgress(fileRef),
        signal: fileRef?.current?.controller?.signal,
      })
      .then((response) => response)
      .catch((error) => {
        handleError({ logMessage: error });
        return error;
      });
  };

  const uploadImageFile = (blobInfo, id: string, type: 'report_field' | 'addendum', endppoint) => {
    const blobData = blobInfo.blob();
    const filename = blobInfo.filename();
    const imageFile = new File([blobData], filename);
    const formData = new FormData();
    formData.append('image', imageFile);
    formData.append(type, id);

    return restClient
      .post(`${endppoint}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...getAuthHeaders(),
        },
      })
      .then((response) => response)
      .catch((error) => {
        handleError({ logMessage: error });
        return error;
      });
  };

  const createAddendum = async (reportId: string, templateId: string) => {
    const { data } = await createAddendumMutation({
      variables: {
        input: {
          report: reportId,
          template: templateId,
        },
      },
    });
    return data;
  };

  const updateAddendum = async (addendumId: string, templateId: string) => {
    const { data } = await updateAddendumMutation({
      variables: {
        id: addendumId,
        input: {
          template: templateId,
        },
      },
    });
    return data;
  };

  const signAddendum = async (addendumId: string) => {
    const { data } = await signAddendumMutation({
      variables: {
        id: addendumId,
      },
    });
    return data;
  };

  const cancelAddendum = async (addendumId: string) => {
    const { data } = await cancelAddendumMutation({
      variables: {
        id: addendumId,
      },
    });
    return data;
  };

  const formatValueContent = (value: string): string => {
    if (!value) return '';

    let formattedValue = value.replace(/<p>((<br\s*\/?>\s*)+)<\/p>/gi, (_, brTags) => brTags);

    formattedValue = formattedValue.replace(/(?:<p[^>]*>\s*(?:&nbsp;|\s)*\s*<\/p>\s*)+$/gi, '');

    return formattedValue;
  };

  const updateReportField = async (id: string, value: string) => {
    const formatContentValue = formatValueContent(value);
    const { data } = await updateReportFieldMutation({
      variables: {
        id,
        input: { value: formatContentValue },
      },
    });
    return data;
  };

  const updateAddendumField = async (addendumFieldId: string, value: string) => {
    const formatContentValue = formatValueContent(value);
    const { data } = await updateAddendumFieldMutation({
      variables: {
        addendumFieldId,
        input: {
          value: formatContentValue,
        },
      },
    });
    return data;
  };

  // Export useReportService values
  return {
    activeReport,
    clearReport,
    rebuildActiveReportPDF,
    uploadReportPDF,
    createAddendum,
    updateAddendum,
    signAddendum,
    cancelAddendum,
    updateReportField,
    uploadImageFile,
    isReportUpdating,
    updateAddendumField,
  };
};
