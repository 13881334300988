import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalFactory, ToastPropsV2, ToastVariant, useToast } from '@evacenter/eden';

import { shallow } from 'zustand/shallow';

import {
  StudyStatus,
  StudyType,
  StudyUrgencyLevel,
  useGetStudyByIdLazyQuery,
  useUpdateStudyMutation,
  useRecallChangeStudyStatusMutation,
} from '@eva-pacs/client';
import { ComplementStudy, RecaptureStudy } from '@eva-tech/web-apps-shared-react';

import { Study } from '~/src/types/Study';
import { useStudyListStore } from '~/src/store';
import { useErrorHandler } from '~/utils/appHelpers';
import { useStudyListTabs } from './useStudyListTabs';
import { PAGE_SIZE_INFINITE_SCROLL, PAGE_SIZE } from '~/constants';
import { useWorklistQueries } from '../worklist/useWorklistQueries';
import { studyDTO } from '~/src/dtos/study/studyDTO';
import { Specialty } from '~/src/types/Specialty';
import { DefaultListTabNames } from '~/src/types/StudyList';

export enum AddStudyPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

enum UpdateStudyErrorCodes {
  SIGNED_STUDY_EXCEPTION = 'signed_study_exception',
}

const deepEqual = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) return true;

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
};

export const arraysAreEqual = (arr1: Array<Specialty>, arr2: Array<Specialty>): boolean => {
  if (arr1.length !== arr2.length) return false;

  return arr1.every((item, index) => deepEqual(item, arr2[index]));
};

export const useStudyActions = () => {
  const [
    getStudy,
    addStudyToBottom,
    removeStudy,
    addStudies,
    updateStudy,
    updateStudyData,
    resetStudies,
    setWorklistTotalCount,
    getStudies,
    addStudyToTop,
    softResetStudies,
    getLastStudyId,
    getWorklistTotalCount,
    enabledInfiniteScroll,
  ] = useStudyListStore(
    (state) => [
      state.getStudy,
      state.addStudyToBottom,
      state.removeStudy,
      state.addStudies,
      state.updateStudy,
      state.updateStudyData,
      state.resetStudies,
      state.setWorklistTotalCount,
      state.getStudies,
      state.addStudyToTop,
      state.softResetAndAddNewStudies,
      state.getLastStudyId,
      state.getWorklistTotalCount,
      state.enabledInfiniteScroll,
    ],
    shallow,
  );

  const { fetchNextWorklistStudyById } = useWorklistQueries();
  const { addToast } = useToast();
  const { t } = useTranslation();

  const { activeTab } = useStudyListTabs();

  const pageSize = useMemo(() => {
    if (enabledInfiniteScroll) return PAGE_SIZE_INFINITE_SCROLL;

    return PAGE_SIZE;
  }, [enabledInfiniteScroll]);

  const { handleError } = useErrorHandler();
  const [updateStudyMutation] = useUpdateStudyMutation();
  const [updateRecallStatus] = useRecallChangeStudyStatusMutation();
  const [getStudyById] = useGetStudyByIdLazyQuery({
    onCompleted: (data) => {
      if (data?.study) {
        const parsedStudy = studyDTO(data.study as StudyType, false, false);
        performUpdateStudy(data.study.id, parsedStudy);
      }
    },
  });

  const performUpdateStatus = async (
    id: string,
    status: StudyStatus,
    openModal: <ModalResult>(modalFactory: ModalFactory<ModalResult>) => Promise<ModalResult | undefined>,
  ) => {
    const studyBeforeChanges = getStudy(id);
    try {
      if (status === StudyStatus.RECALL_PENDING) {
        openModal<boolean>((close) => (
          <ComplementStudy
            onClose={(modalResult) => close(modalResult)}
            studyId={id}
            onComplement={({ recallOptionText, recallReason }) => {
              updateStudyData(id, {
                studyStatus: status,
                inRecall: true,
                recallOptionText: recallOptionText,
                recallNote: recallReason,
              });
            }}
          />
        ));
        return;
      }
      if (status === StudyStatus.RECALL_REQUESTED) {
        const result = await openModal<boolean>((close) => (
          <RecaptureStudy
            onClose={(modalResult) => close(modalResult)}
            studyId={id}
            onRecapture={({ recallOptionText, recallReason }) => {
              updateStudyData(id, {
                studyStatus: status,
                inRecall: true,
                recallOptionText: recallOptionText,
                recallNote: recallReason,
              });
            }}
          />
        ));
        if (!result) return;
      }
      if (status === StudyStatus.READING_PENDING) {
        updateStudyData(id, {
          studyStatus: status,
          inRecall: false,
        });
        const { data } = await updateRecallStatus({
          variables: {
            id,
            status,
          },
        });
        if (!data?.recallChangeStudyStatus.success) {
          // throwing error to be catched by the catch block if the error code is not catched in front yet.
          throw new Error(data?.recallChangeStudyStatus.errors);
        }
      }
    } catch (error) {
      updateStudyData(id, { studyStatus: studyBeforeChanges.studyStatus, inRecall: studyBeforeChanges.inRecall });
      handleError({ logMessage: error });
    }
  };

  const performAddStudy = (study: Study, position = AddStudyPosition.BOTTOM) => {
    checkForSpaceToInsert();
    if (position === AddStudyPosition.TOP) {
      return addStudyToTop(study);
    }

    addStudyToBottom(study);
  };

  const checkForSpaceToInsert = () => {
    const currentStudies = getStudies();
    const arrayOfStudies = Object.values(currentStudies);

    const tempArray: Array<Study> = arrayOfStudies.map((study) => study);

    // If we have full the current page, remove the last element so a new study can
    // be inserted.
    if (tempArray.length >= pageSize) {
      tempArray.pop();
      softResetStudies(tempArray);
    }
  };

  const incrementWorklistTotalCount = () => {
    const totalCount = getWorklistTotalCount();
    setWorklistTotalCount(totalCount + 1);
  };

  const decrementWorklistTotalCount = () => {
    const totalCount = getWorklistTotalCount();
    if (totalCount === 0) return;

    setWorklistTotalCount(totalCount - 1);
  };

  const performAddStudies = (studies: Array<Study>) => addStudies(studies);

  const performRemoveStudy = (id: string) => removeStudy(id);

  const performUpdateStudy = (studyId: string, newStudy: Study) => updateStudy(studyId, newStudy);

  const processShiftWorklistStudies = async (studyId: string) => {
    const newStudy = await fetchNextWorklistStudyById(studyId);
    if (!newStudy) return;
    const parsedStudy = studyDTO(newStudy, false, true);

    performAddStudy(parsedStudy);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const processShiftStudyListStudies = async (studyId: string) => {
    // TODO: Remove eslint disable and add logic here for shifting with study by id queries for study list.
  };

  const performShiftStudiesBackwards = () => {
    try {
      const lastStudyId = getLastStudyId();
      if (!lastStudyId) return;

      if (activeTab === DefaultListTabNames.WORKLIST) return processShiftWorklistStudies(lastStudyId);

      return processShiftStudyListStudies(lastStudyId);
    } catch (error) {
      console.error(error);
    }
  };

  const updateStudyFromApi = (studyId: string) => {
    getStudyById({
      variables: {
        id: studyId,
      },
    });
  };

  const performUpdatePractitioner = async (id: string, value: string, practitionerName?: string) => {
    const study = getStudy(id);
    try {
      const newStudy: Study = {
        ...study,
        practitionerAssignedId: value,
        practitionerAssignedFullName: practitionerName,
      };
      performUpdateStudy(id, newStudy);
      const { data } = await updateStudyMutation({
        variables: {
          id,
          input: {
            practitionerAssigned: value || null,
          },
        },
      });

      if (data?.updateStudy.success)
        return addToast({
          variant: ToastVariant.success,
          title: t('study.studyTable.tableActions.practitionerAssignedTitle'),
          subtitle: t('study.studyTable.tableActions.practitionerAssignedDescription', { medicName: practitionerName }),
          dismissInterval: 5000,
        } as ToastPropsV2);

      // TODO[Chava]: Review global message with product: https://eva-pacs.atlassian.net/browse/EVA-5025
      if (!data?.updateStudy.success) {
        const errorCode = data?.updateStudy.errors.system[0].code;
        if (errorCode === UpdateStudyErrorCodes.SIGNED_STUDY_EXCEPTION) {
          const studyToUpdate = {
            ...study,
            studyStatus: StudyStatus.SIGNED,
          };
          // At this point we know that this study is signed, so we update the study status to signed
          // leveraging optimistic UI.
          performUpdateStudy(id, studyToUpdate);

          return addToast({
            variant: ToastVariant.error,
            title: t('study.studyTable.tableActions.signedStudyExceptionTitle'),
            subtitle: t('study.studyTable.tableActions.signedStudyExceptionDescription'),
            dismissInterval: 5000,
          } as ToastPropsV2);
        }

        // throwing error to be catched by the catch block if the error code is not catched in front yet.
        throw new Error(data?.updateStudy.errors);
      }
    } catch (error) {
      performUpdateStudy(id, study);
      handleError({ logMessage: error });
    }
  };

  const performUpdateDicomDescription = async (id: string, value: string) => {
    const study = getStudy(id);
    try {
      const newStudy: Study = {
        ...study,
        dicomDescription: value,
      };
      performUpdateStudy(id, newStudy);
      const { data } = await updateStudyMutation({
        variables: {
          id,
          input: {
            dicomDescription: value || null,
          },
        },
      });

      if (data?.updateStudy.success) {
        updateStudyFromApi(id);
        if (data.updateStudy.updatedSpecialties)
          return addToast({
            variant: ToastVariant.success,
            title: t('study.studyTable.tableActions.studyDescriptionWithSpecialtiesUpdated'),
            dismissInterval: 5000,
          } as ToastPropsV2);

        return addToast({
          variant: ToastVariant.success,
          title: t('study.studyTable.tableActions.studyDescriptionUpdated'),
          dismissInterval: 5000,
        } as ToastPropsV2);
      }

      // TODO[Chava]: Review global message with product: https://eva-pacs.atlassian.net/browse/EVA-5025
      if (!data?.updateStudy.success) throw new Error(data?.updateStudy.errors);
    } catch (error) {
      performUpdateStudy(id, study);
      handleError({ logMessage: error });
    }
  };

  const performUpdatePriority = async (id: string, value: StudyUrgencyLevel) => {
    const study = getStudy(id);
    try {
      const newStudy: Study = {
        ...study,
        urgencyLevel: value?.trim() as StudyUrgencyLevel,
      };
      updateStudy(id, newStudy);
      const { data } = await updateStudyMutation({
        variables: {
          id,
          input: {
            urgencyLevel: StudyUrgencyLevel[value.toUpperCase()],
          },
        },
      });
      // TODO[Chava]: Review global message with product: https://eva-pacs.atlassian.net/browse/EVA-5025
      if (!data?.updateStudy.success) {
        const errorCode = data?.updateStudy.errors.system[0].code;
        if (errorCode === UpdateStudyErrorCodes.SIGNED_STUDY_EXCEPTION) {
          const studyToUpdate = {
            ...study,
            studyStatus: StudyStatus.SIGNED,
          };
          // At this point we know that this study is signed, so we update the study status to signed
          // leveraging optimistic UI.
          performUpdateStudy(id, studyToUpdate);

          return addToast({
            variant: ToastVariant.error,
            title: t('study.studyTable.tableActions.slaErrorTitle'),
            subtitle: t('study.studyTable.tableActions.slaErrorDescription'),
            dismissInterval: 5000,
          } as ToastPropsV2);
        }

        // throwing error to be catched by the catch block if the error code is not catched in front yet.
        throw new Error(data?.updateStudy.errors);
      }

      return addToast({
        variant: ToastVariant.success,
        title: t('study.studyTable.tableActions.priorityUpdated'),
        dismissInterval: 5000,
      } as ToastPropsV2);
    } catch (error) {
      updateStudy(id, study);
      handleError({ logMessage: error });
    }
  };

  const performUpdateReportExpiringDate = async (id: string, value: string) => {
    const study = getStudy(id);
    try {
      const newStudy: Study = {
        ...study,
        reportExpiringDate: value,
      };
      updateStudy(id, newStudy);
      const { data } = await updateStudyMutation({
        variables: {
          id,
          input: {
            reportExpiringDate: value,
          },
        },
      });
      // TODO[Chava]: Review global message with product: https://eva-pacs.atlassian.net/browse/EVA-5025
      if (!data?.updateStudy.success) throw new Error(data?.updateStudy.errors);

      return addToast({
        variant: ToastVariant.success,
        title: t('study.studyTable.tableActions.slaUpdated'),
        dismissInterval: 5000,
      } as ToastPropsV2);
    } catch (error) {
      updateStudy(id, study);
      handleError({ logMessage: error });
    }
  };

  const performUpdateSpecialties = async (id: string, newSpecialties: Array<Specialty>) => {
    const study = getStudy(id);
    try {
      if (
        study.specialties &&
        newSpecialties &&
        arraysAreEqual(study.specialties.filter((s) => s !== null) as Specialty[], newSpecialties)
      )
        return;
      const newStudy: Study = {
        ...study,
        specialties: newSpecialties,
      };

      updateStudy(id, newStudy);
      const specialtiesIds = newSpecialties?.map((specialty) => specialty?.id);
      const { data } = await updateStudyMutation({
        variables: {
          id,
          input: {
            specialties: specialtiesIds,
          },
        },
      });

      // TODO[Chava]: Review global message with product: https://eva-pacs.atlassian.net/browse/EVA-5025
      if (!data?.updateStudy.success) throw new Error(data?.updateStudy.errors);

      return addToast({
        variant: ToastVariant.success,
        title: t('study.studyTable.tableActions.specialtyUpdateSuccessful'),
        dismissInterval: 5000,
      } as ToastPropsV2);
    } catch (error) {
      updateStudy(id, study);
      handleError({ logMessage: error });
    }
  };

  const performUpdateReviewerPractitioner = async (id: string, value: string, label?: string) => {
    const study = getStudy(id);
    try {
      const newStudy: Study = {
        ...study,
        reviewerPractitionerId: value,
        reviewerPractitionerFullName: label,
      };
      performUpdateStudy(id, newStudy);
      const { data } = await updateStudyMutation({
        variables: {
          id,
          input: {
            reviewerPractitioner: value || null,
          },
        },
      });

      if (data?.updateStudy.success)
        return addToast({
          variant: ToastVariant.success,
          title: t('study.studyTable.tableActions.practitionerAssignedTitle'),
          subtitle: t('study.studyTable.tableActions.practitionerAssignedDescription', { medicName: label }),
          dismissInterval: 5000,
        } as ToastPropsV2);

      // TODO[Chava]: Review global message with product: https://eva-pacs.atlassian.net/browse/EVA-5025
      if (!data?.updateStudy.success) throw new Error(data?.updateStudy.errors);
    } catch (error) {
      performUpdateStudy(id, study);
      handleError({ logMessage: error });
    }
  };

  const performReset = () => {
    resetStudies();
  };

  return {
    performUpdateReviewerPractitioner,
    performAddStudy,
    performRemoveStudy,
    performAddStudies,
    incrementWorklistTotalCount,
    decrementWorklistTotalCount,
    performUpdateSpecialties,
    performShiftStudiesBackwards,
    performUpdateStudy,
    performUpdatePractitioner,
    performUpdateDicomDescription,
    performUpdatePriority,
    performUpdateReportExpiringDate,
    performReset,
    performUpdateStatus,
  };
};
