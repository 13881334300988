var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { getCookie, ACCESS_TOKEN_KEY, initIaraHealthService, initEdenApiService, EdenApiService, IaraHealthService, } from '@eva-pacs/core';
import { Button, IconCatalog, ButtonVariant, ButtonSize, useModal, BaseModal, ModalSize, useToast, ToastVariant, } from '@evacenter/eden';
export var IaraErrorStates;
(function (IaraErrorStates) {
    IaraErrorStates["INIT_INSTALL_ERROR"] = "INIT_INSTALL_ERROR";
    IaraErrorStates["ALS_OFFLINE_ERROR"] = "ALS_OFFLINE_ERROR";
    IaraErrorStates["GENERAL_ERROR"] = "GENERAL_ERROR";
})(IaraErrorStates || (IaraErrorStates = {}));
/**
 * Button to record audio and convert it to text. This uses our new transcriptions API (Transcriberv2).
 * @author Salvador Gonzalez<salvador.gonzalez@evacenter.com>
 * Created at 2024-01-24
 */
export var RecordAudioButtonV2 = forwardRef(function (_a, ref) {
    var className = _a.className, onRecord = _a.onRecord, onTerminate = _a.onTerminate, studyId = _a.studyId, reportField = _a.reportField, isRecording = _a.isRecording, editorRef = _a.editorRef, organizationId = _a.organizationId, onClick = _a.onClick, userEmail = _a.userEmail, apiTokenIara = _a.apiTokenIara, onSuccessfullConnection = _a.onSuccessfullConnection, onSpeechRecognitionVadStart = _a.onSpeechRecognitionVadStart, onSpeechRecognitionVadStop = _a.onSpeechRecognitionVadStop, isIaraEnabled = _a.isIaraEnabled;
    var _b = useState(false), isDisabled = _b[0], setIsDisabled = _b[1];
    var _c = useState(false), isLoading = _c[0], setIsLoading = _c[1];
    var _d = useState(null), closeModal = _d[0], setCloseModal = _d[1];
    var addToast = useToast().addToast;
    var _e = useModal(), modalNode = _e.modalNode, openModal = _e.openModal;
    var currentSessionToken = getCookie(ACCESS_TOKEN_KEY);
    var t = useTranslation().t;
    var handleConfirmationInitInstall = function () { return __awaiter(void 0, void 0, void 0, function () {
        var handleDownloadIaraForWindows, handleDownloadForMac, confirmation;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    handleDownloadIaraForWindows = function () { return window.open('https://desktop.iarahealth.com/download/windows'); };
                    handleDownloadForMac = function () { return window.open('https://desktop.iarahealth.com/download/osx'); };
                    return [4 /*yield*/, openModal(function (close) {
                            setCloseModal(function () { return close; });
                            return (React.createElement(BaseModal, { header: {
                                    hasCloseBtn: true,
                                    title: t('report.modal.iara.downloadIARA.title'),
                                }, size: ModalSize.lg, body: React.createElement("div", { className: "e-text-base-white e-text-base" },
                                    React.createElement("p", null, t('report.modal.iara.downloadIARA.description'))), onClose: close, footer: React.createElement("div", { className: "e-flex e-gap-3 mt-3" },
                                    React.createElement(Button, { variant: ButtonVariant.tertiary, size: ButtonSize.sm, onClick: handleDownloadIaraForWindows, endIcon: IconCatalog.download }, t('report.modal.iara.downloadIARA.downloadForWindows')),
                                    React.createElement(Button, { variant: ButtonVariant.tertiary, size: ButtonSize.sm, onClick: handleDownloadForMac, endIcon: IconCatalog.download }, t('report.modal.iara.downloadIARA.downloadForMac'))) }));
                        })];
                case 1:
                    confirmation = _a.sent();
                    return [2 /*return*/, confirmation];
            }
        });
    }); };
    var handleConfirmationALSOffline = function () { return __awaiter(void 0, void 0, void 0, function () {
        var confirmation;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, openModal(function (close) {
                        setCloseModal(function () { return close; });
                        return (React.createElement(BaseModal, { header: {
                                hasCloseBtn: true,
                                title: t('report.modal.iara.iaraOfflineErrorTitle'),
                            }, size: ModalSize.lg, body: React.createElement("div", { className: "e-text-base-white e-text-base" },
                                React.createElement("p", null, t('report.modal.iara.iaraOfflineErrorDescription'))), onClose: close }));
                    })];
                case 1:
                    confirmation = _a.sent();
                    return [2 /*return*/, confirmation];
            }
        });
    }); };
    var getEdenTranscriberSink = function () {
        return {
            onTranscript: function (content) {
                onRecord(content);
            },
            onError: function (message) {
                resetRecordState();
                console.warn(message);
                return addToast({
                    variant: ToastVariant.error,
                    title: t('transcriptions.transcriptionsEden.onError'),
                    dismissInterval: 5000,
                });
            },
            onSuccessfullConnection: function () {
                startRecordState();
                handleButtonFinishLoadingState();
                onSuccessfullConnection();
            },
            onTerminate: function (message) {
                if (onTerminate)
                    onTerminate(message);
                handleButtonFinishLoadingState();
                resetRecordState();
            },
            studyId: studyId,
            reportField: reportField,
            organizationId: organizationId,
            sessionToken: currentSessionToken,
        };
    };
    var resetRecordState = function () {
        if (!isRecording)
            return;
        isRecording.current = false;
        setIsDisabled(false);
        setIsLoading(false);
    };
    var startRecordState = function () {
        if (!isRecording)
            return;
        isRecording.current = true;
    };
    var getIaraTranscriberSink = function () {
        return {
            onTranscript: function (content) {
                onRecord(content);
            },
            onInitStart: function () {
                if (closeModal)
                    closeModal();
                handleButtonLoadingState();
            },
            onFinishInit: function () {
                handleButtonFinishLoadingState();
                onSuccessfullConnection();
                startRecordState();
            },
            onError: function (message) {
                console.warn(message);
                addToast({
                    variant: ToastVariant.error,
                    title: t('transcriptions.transcriptionsIara.onError'),
                    subtitle: message,
                    dismissInterval: 15000,
                });
                resetIaraInstance();
                resetRecordState();
            },
            onSuccessfullConnection: function () {
                startRecordState();
                handleButtonFinishLoadingState();
                onSuccessfullConnection();
            },
            onALSOffline: function () {
                resetRecordState();
                resetIaraInstance();
                handleConfirmationALSOffline();
            },
            onALSOnline: function () {
                // setIaraError(null);
            },
            onTerminate: function (message) {
                if (onTerminate)
                    onTerminate(message);
                handleButtonFinishLoadingState();
                resetRecordState();
            },
            onInitInstallError: function (message) {
                resetRecordState();
                resetIaraInstance();
                handleConfirmationInitInstall();
                console.warn(message);
            },
            onSpeechRecognitionVadStart: onSpeechRecognitionVadStart,
            onSpeechRecognitionVadStop: onSpeechRecognitionVadStop,
            apiTokenIara: apiTokenIara || '',
            userEmail: userEmail || '',
        };
    };
    var classes = {
        startDictateButtonClass: cn('right-0 absolute w-8', className, {
            'hide-record-button': isRecording === null || isRecording === void 0 ? void 0 : isRecording.current,
            'show-record-button': !(isRecording === null || isRecording === void 0 ? void 0 : isRecording.current),
        }),
        stopDictateButtonClass: cn('right-0 absolute w-8', className, {
            'show-record-button': isRecording === null || isRecording === void 0 ? void 0 : isRecording.current,
            'hide-record-button': !(isRecording === null || isRecording === void 0 ? void 0 : isRecording.current),
        }),
    };
    var handleButtonLoadingState = function () {
        setIsLoading(true);
        setIsDisabled(true);
    };
    var resetIaraInstance = function () {
        IaraHealthService.clearInstance();
    };
    var handleButtonFinishLoadingState = function () {
        setIsLoading(false);
        setIsDisabled(false);
    };
    function getPreviousWordTinyMCE(editor) {
        var _a;
        var range = (_a = editor === null || editor === void 0 ? void 0 : editor.selection) === null || _a === void 0 ? void 0 : _a.getRng();
        var node = range === null || range === void 0 ? void 0 : range.startContainer;
        var offset = range === null || range === void 0 ? void 0 : range.startOffset;
        if (!node)
            return '';
        var textBeforeCursor = node.textContent.substring(0, offset);
        var match = textBeforeCursor.match(/([\wáéíóúüñÁÉÍÓÚÜÑ]+[.,;:!?(){}[\]"“”]*|\s[.,;:!?(){}[\]"“”])\s*$/);
        return match ? match[1] : '';
    }
    var getLocalInstance = function () {
        var transcriberSink = getEdenTranscriberSink();
        initEdenApiService(transcriberSink);
        var transcriber = EdenApiService.getInstance(transcriberSink).getTranscriber();
        return transcriber;
    };
    var handleClickIaraService = function () {
        if (onClick)
            onClick();
        var iaraServiceInstance = IaraHealthService.getInstance();
        // If the instance is not yet created, we should initialize it.
        // After the 1st init, the instance internally is going to start the recognition.
        if (!iaraServiceInstance) {
            var transcriberSink = getIaraTranscriberSink();
            initIaraHealthService(transcriberSink);
            return;
        }
        if (isRecording === null || isRecording === void 0 ? void 0 : isRecording.current) {
            return iaraServiceInstance.stop();
        }
        iaraServiceInstance.start();
    };
    var handleClickEdenService = function () {
        if (onClick)
            onClick();
        handleButtonLoadingState();
        var localInstance = getLocalInstance();
        var previousWord = getPreviousWordTinyMCE(editorRef === null || editorRef === void 0 ? void 0 : editorRef.current);
        localInstance.updateLastWord(previousWord);
        if (isRecording === null || isRecording === void 0 ? void 0 : isRecording.current) {
            return localInstance.stop();
        }
        try {
            localInstance.start();
        }
        catch (error) {
            handleButtonFinishLoadingState();
        }
    };
    var orchestrateTranscriptionService = function () {
        if (isIaraEnabled) {
            return handleClickIaraService();
        }
        handleClickEdenService();
    };
    var handleOnClick = function () { return orchestrateTranscriptionService(); };
    useImperativeHandle(ref, function () { return ({
        onClickButton: function () { return handleOnClick(); },
    }); });
    var commonButtonProps = {
        isDisabled: isDisabled,
        disabled: isDisabled,
        isLoading: isLoading,
        size: ButtonSize.xs,
        variant: ButtonVariant.ghostNeutral,
        onClick: handleOnClick,
    };
    return (
    // This 2 buttons are intended to support the transition ease-in ease-out;
    // from changing into a different state.
    React.createElement(React.Fragment, null,
        React.createElement(Button, __assign({ id: "start-button", "data-testid": "start-button" }, commonButtonProps, { className: classes.startDictateButtonClass, endIcon: IconCatalog.voice }), t('report.actions.startDictate')),
        React.createElement(Button, __assign({ id: "stop-button", "data-testid": "stop-button" }, commonButtonProps, { className: classes.stopDictateButtonClass, endIcon: IconCatalog.stopFilled }), t('report.actions.stopDictate')),
        modalNode));
});
