// store.ts
import produce from 'immer';
import { create, GetState, SetState } from 'zustand';

import { Study } from '../types/Study';
import { ListType } from '../types/StudyList';

export const immer = (config) => (set, get) => config((fn) => set(produce(fn)), get);
export interface StudyListStoreState {
  studies: Record<string, Study>;
  studiesBeforeUpdate: Record<string, Study>;
  tableSortBy: string;
  totalCount: number;
  worklistTotalCount: number;
  isSLASorting: boolean;
  currentPage: number;
  reverse: boolean;
  enableGoToTop: boolean;
  enableGoToBottom: boolean;
  listType: ListType;
  enabledInfiniteScroll: boolean;
  setTableSortBy: (sotrBy: string) => void;
  getTableSortBy: () => string;
  getCurrentPage: () => number;
  setIsSLASorting: (isSLASorting: boolean) => void;
  setWorklistTotalCount: (total: number) => void;
  setCurrentPage: (currentPage: number) => void;
  clearStudyAnimationEntrance: (studyId: string) => void;
  softResetAndAddNewStudies: (studies: Array<Study>) => void;
  setTotalCount: (total: number) => void;
  getStudy: (id: string) => Study;
  getStudies: () => Record<string, Study>;
  getStudiesBeforeUpdate: () => Record<string, Study>;
  resetStudiesBeforeUpdate: () => void;
  getLastStudyId: () => string;
  getWorklistTotalCount: () => number;
  addStudyToBottom: (study: Study) => void;
  addStudyToTop: (study: Study) => void;
  addStudies: (studies: Array<Study>) => void;
  updateStudy: (id: string, newStudy: Study) => void;
  updateStudyData: (id: string, studyData: Partial<Study>) => void;
  removeStudy: (id: string) => void;
  resetStudies: () => void;
  setReverse: (reverse: boolean) => void;
  setEnableGoToTop: (enableGoToTop: boolean) => void;
  setEnableGoToBottom: (enableGoToBottom: boolean) => void;
  setListType: (listType: ListType) => void;
}

const formatStudiesToRecord = (studies: Array<Study>): Record<string, Study> => {
  const resultDict: Record<string, Study> = {};

  studies.forEach((study) => {
    if (!study.id) return;
    resultDict[study.id.toString()] = study;
  });

  return resultDict;
};

const studyListStore = (
  set: SetState<StudyListStoreState>,
  get: GetState<StudyListStoreState>,
): StudyListStoreState => ({
  studies: {},
  studiesBeforeUpdate: {},
  tableSortBy: '',
  worklistTotalCount: 0,
  totalCount: 0,
  currentPage: 1,
  reverse: false,
  enableGoToTop: false,
  enableGoToBottom: true,
  listType: ListType.PAGINATION,
  isSLASorting: false,
  enabledInfiniteScroll: false,
  getStudy: (id) => get().studies[id],
  getStudies: () => get().studies,
  getStudiesBeforeUpdate: () => get().studiesBeforeUpdate,
  getWorklistTotalCount: () => get().worklistTotalCount,
  getTableSortBy: () => get().tableSortBy,
  getLastStudyId: () => {
    const studies = get().studies;
    const array = Object.entries(studies);
    if (array.length <= 0 || !array) return '';
    const lastElement = array[array.length - 1];
    if (!lastElement?.length) return '';
    return (lastElement[0] as string) || '';
  },
  getCurrentPage: () => get().currentPage,
  setCurrentPage: (currentPage: number) =>
    set((state) => {
      state.currentPage = currentPage;
      return state;
    }),
  setIsSLASorting: (isSLASorting: boolean) => set((state) => ({ ...state, isSLASorting })),
  clearStudyAnimationEntrance: (studyId: string) =>
    set((state) => {
      if (!state.studies[studyId]) return state;
      const prevStudy = state.studies[studyId];
      const parsedStudy: Study = {
        ...prevStudy,
        enableEntranceAnimation: false,
        isSwapStudy: false,
      };
      state.studies[studyId] = parsedStudy;
      return state;
    }),
  addStudyToBottom: (study) =>
    set((state) => {
      if (!study.id) return state;
      const studies = { ...state.studies, [study.id]: study } as Record<string, Study>;
      state.studiesBeforeUpdate = studies;
      state.studies = studies;
      return state;
    }),
  addStudyToTop: (study) =>
    set((state) => {
      if (!study.id) return state;
      const studies = { [study.id]: study, ...state.studies } as Record<string, Study>;
      state.studiesBeforeUpdate = studies;
      state.studies = studies;
      return state;
    }),
  softResetAndAddNewStudies: (studies) => {
    set((state) => {
      const formattedStudies = formatStudiesToRecord(studies);
      state.studies = formattedStudies;
      state.studiesBeforeUpdate = formattedStudies;
      return state;
    });
  },
  addStudies: (studies) => {
    set((state) => {
      const formattedStudies = formatStudiesToRecord(studies);
      const parsedStudies = state.reverse
        ? { ...formattedStudies, ...state.studies }
        : { ...state.studies, ...formattedStudies };
      return {
        studiesBeforeUpdate: parsedStudies, // Studies and prev studies should be the same when adding a bunch.
        studies: parsedStudies,
      };
    });
  },
  updateStudy: (id, newStudy) =>
    set((state) => {
      return {
        studiesBeforeUpdate: { ...state.studies }, // Save current studies to studiesBeforeUpdate before updating
        studies: { ...state.studies, [id]: newStudy },
      };
    }),
  updateStudyData: (id, studyData) =>
    set((state) => {
      const currentStudy = get().studies[id];
      if (!currentStudy) return state;
      return {
        studies: { ...state.studies, [id]: { ...currentStudy, ...studyData } },
      };
    }),
  removeStudy: (id) =>
    set((state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [id]: _, ...remainingStudies } = state.studies;
      return {
        studiesBeforeUpdate: remainingStudies,
        studies: remainingStudies,
      };
    }),
  setTotalCount: (totalCount) =>
    set((state) => {
      state.totalCount = totalCount;
      return state;
    }),
  setWorklistTotalCount: (worklistTotalCount) =>
    set((state) => {
      state.worklistTotalCount = worklistTotalCount;
      return state;
    }),
  resetStudies: () => set((state) => ({ ...state, studies: {} })),
  resetStudiesBeforeUpdate: () => set((state) => ({ ...state, studiesBeforeUpdate: {} })),
  setTableSortBy: (sortBy: string) => set((state) => ({ ...state, tableSortBy: sortBy })),
  setReverse: (reverse: boolean) => set((state) => ({ ...state, reverse })),
  setEnableGoToTop: (enableGoToTop: boolean) => set((state) => ({ ...state, enableGoToTop })),
  setEnableGoToBottom: (enableGoToBottom: boolean) => set((state) => ({ ...state, enableGoToBottom })),
  setListType: (listType: ListType) =>
    set((state) => ({ ...state, listType, enabledInfiniteScroll: listType === ListType.INFINITE_SCROLL })),
});

export const useStudyListStore = create<StudyListStoreState>(immer(studyListStore));
