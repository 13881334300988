var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import admin from './Admin.json';
import auth from './Auth.json';
import errors from './Errors.json';
import general from './General.json';
import pages from './Pages.json';
import report from './Report.json';
import study from './Study.json';
import viewer from './Viewer.json';
import offline from './Offline.json';
import frigade from './Frigade.json';
import transcriptions from './Transcriptions.json';
import newviewer from './NewViewer.json';
export default __assign(__assign({}, newviewer), { admin: admin, auth: auth, errors: errors, general: general, pages: pages, report: report, study: study, viewer: viewer, offline: offline, frigade: frigade, transcriptions: transcriptions });
