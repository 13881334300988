import { FileExtension, FileType } from '@eva-pacs/core';

export enum PRINT_TOOLS_STATUS {
  WAITING = 'WAITING',
  LOADING = 'LOADING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export enum PRINTING_QUALITY {
  normal = 'normal',
  high = 'high',
  ultra = 'ultra',
}

export enum PRINTING_TEXT_OPTIONS {
  LEFT = 'left',
  RIGHT = 'right',
  NO_TEXT = 'no-text',
  MAMMOGRAPHY = 'mastography',
}

export enum PRINTING_TYPE_OPTIONS {
  ACTUAL = 'actual view',
  COMPLETE = 'complete series',
}

export const PRINTING_DPI_NORMAL = 72;
export const PRINTING_DPI_HIGH = 300;
export const PRINTING_DPI_ULTRA = 600;
export const BACKEND_PRINT_LIMIT = 200;

export const FORMAT_OPTIONS = [
  {
    label: 'PDF',
    value: FileExtension.PDF,
  },
  {
    label: 'JPG',
    value: FileExtension.JPG,
  },
  {
    label: 'viewer.toolbar.printToolbar.dicomPrinter',
    value: FileType.DICOM,
  },
];

export const VISIBILITY_OPTIONS = [
  {
    label: 'viewer.toolbar.printToolbar.noText',
    value: PRINTING_TEXT_OPTIONS.NO_TEXT,
  },
  {
    label: 'viewer.toolbar.printToolbar.right',
    value: PRINTING_TEXT_OPTIONS.RIGHT,
  },
  {
    label: 'viewer.toolbar.printToolbar.left',
    value: PRINTING_TEXT_OPTIONS.LEFT,
  },
  {
    label: 'viewer.toolbar.printToolbar.mammography',
    value: PRINTING_TEXT_OPTIONS.MAMMOGRAPHY,
  },
];

export const QUALITY_OPTIONS = [
  {
    label: 'viewer.toolbar.printToolbar.normal',
    value: PRINTING_QUALITY.normal,
  },
  {
    label: 'viewer.toolbar.printToolbar.high',
    value: PRINTING_QUALITY.high,
  },
  {
    label: 'viewer.toolbar.printToolbar.ultra',
    value: PRINTING_QUALITY.ultra,
  },
];

export const TYPE_OPTIONS = [
  {
    label: 'viewer.toolbar.printToolbar.currentView',
    value: PRINTING_TYPE_OPTIONS.ACTUAL,
  },
  {
    label: 'viewer.toolbar.printToolbar.completeSeries',
    value: PRINTING_TYPE_OPTIONS.COMPLETE,
  },
];

export const PAGE_SIZE_PRINTING_OPTIONS = [
  {
    value: 'letter',
    label: 'viewer.toolbar.printToolbar.letterP',
  },
  {
    value: 'letter-landscape',
    label: 'viewer.toolbar.printToolbar.letterH',
  },
  {
    value: 'legal',
    label: 'viewer.toolbar.printToolbar.legalP',
  },
  {
    value: 'legal-landscape',
    label: 'viewer.toolbar.printToolbar.legalH',
  },
  {
    value: 'tabloid',
    label: 'viewer.toolbar.printToolbar.tabloidP',
  },
  {
    value: 'tabloid-landscape',
    label: 'viewer.toolbar.printToolbar.tabloidH',
  },
  {
    value: 'A4P',
    label: 'viewer.toolbar.printToolbar.A4P',
  },
  {
    value: 'A4H',
    label: 'viewer.toolbar.printToolbar.A4H',
  },
  {
    value: 'A3P',
    label: 'viewer.toolbar.printToolbar.A3P',
  },
  {
    value: 'A3H',
    label: 'viewer.toolbar.printToolbar.A3H',
  },
  {
    value: 'dicom8x10',
    label: 'viewer.toolbar.printToolbar.dicom8x10',
  },
  {
    value: 'dicom10x8',
    label: 'viewer.toolbar.printToolbar.dicom10x8',
  },
  {
    value: 'dicom10x12',
    label: 'viewer.toolbar.printToolbar.dicom10x12',
  },
  {
    value: 'dicom12x10',
    label: 'viewer.toolbar.printToolbar.dicom12x10',
  },
  {
    value: 'dicom14x17',
    label: 'viewer.toolbar.printToolbar.dicom14x17',
  },
  {
    value: 'dicom17x14',
    label: 'viewer.toolbar.printToolbar.dicom17x14',
  },
  {
    value: 'dicom11x14',
    label: 'viewer.toolbar.printToolbar.dicom11x14',
  },
  {
    value: 'dicom14x11',
    label: 'viewer.toolbar.printToolbar.dicom14x11',
  },
  {
    value: 'dicom10x14',
    label: 'viewer.toolbar.printToolbar.dicom10x14',
  },
  {
    value: 'dicom14x10',
    label: 'viewer.toolbar.printToolbar.dicom14x10',
  },
];
