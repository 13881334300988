import produce from 'immer';
import { create, GetState, SetState } from 'zustand';

import { CustomStudyList } from '../types/CustomStudyList';

export const immer = (config) => (set, get) => config((fn) => set(produce(fn)), get);

export interface CustomStudyListStoreState {
  initialLoad: boolean;
  currentCustomStudyListId: string;
  customStudyLists: Record<string, CustomStudyList>;
  setCustomStudyLists: (customStudyLists: Array<CustomStudyList>) => void;
  addCustomStudyList: (customStudyList: CustomStudyList) => void;
  deleteCustomStudyList: (customStudyListId: string) => void;
  setCurrentCustomStudyListId: (customStudyListId: string) => void;
  getCurrentCustomStudyList: () => CustomStudyList;
}

const formatCustomStudyListsToRecord = (customStudyLists: Array<CustomStudyList>): Record<string, CustomStudyList> => {
  const resultDict: Record<string, CustomStudyList> = {};

  customStudyLists.forEach((customStudyList) => {
    if (!customStudyList.id) return;
    resultDict[customStudyList.id.toString()] = customStudyList;
  });

  return resultDict;
};

const customStudyListStore = (set: SetState<CustomStudyListStoreState>, get: GetState<CustomStudyListStoreState>) => ({
  initialLoad: true,
  customStudyLists: {},
  currentCustomStudyList: {},
  setCustomStudyLists: (customStudyLists: Array<CustomStudyList>) =>
    set((state) => {
      const sortedCustomStudyLists = customStudyLists.sort((a, b) => Number(b.isGlobal) - Number(a.isGlobal));
      const formattedCustomStudyLists = formatCustomStudyListsToRecord(sortedCustomStudyLists);
      state.customStudyLists = formattedCustomStudyLists;
      state.initialLoad = false;
      return state;
    }),
  addCustomStudyList: (customStudyList: CustomStudyList) =>
    set((state) => {
      if (!customStudyList.id) return state;
      state.customStudyLists[customStudyList.id] = customStudyList;
      return state;
    }),
  deleteCustomStudyList: (customStudyListId: string) =>
    set((state) => {
      if (!state.customStudyLists[customStudyListId]) return state;
      delete state.customStudyLists[customStudyListId];
      return state;
    }),
  setCurrentCustomStudyListId: (customStudyListId: string) =>
    set((state) => {
      state.currentCustomStudyListId = customStudyListId;
      return state;
    }),
  getCurrentCustomStudyList: () => get().customStudyLists[get().currentCustomStudyListId],
});

export const useCustomStudyListStore = create<CustomStudyListStoreState>(immer(customStudyListStore));
