export enum USER_EVENT_PAGE_LOCATION {
  PACS_PREVIEW_PDF_LOCATION = 'pacs-preview-pdf-location',
  PACS_PREVIEW_QR_LOCATION = 'pacs-preview-qr-location',
  PACS_LEGACY_VIEWER_LOCATION = 'pacs-legacy-viewer-location',
}

export enum USER_EVENT_TYPES {
  DOWNLOAD_REPORT = 'DOWNLOAD_REPORT',
  PRINT_LETTERHEAD_REPORT = 'PRINT_LETTERHEAD_REPORT',
  PRINT_DEFAULT_REPORT = 'PRINT_DEFAULT_REPORT',
  QR_VIEWER_DOWNLOAD_PNG = 'QR_VIEWER_DOWNLOAD_PNG',
  QR_VIEWER_DOWNLOAD_PDF = 'QR_VIEWER_DOWNLOAD_PDF',
  QR_VIEWER_COPY = 'QR_VIEWER_COPY',
}

export enum USER_EVENT_MODEL_TYPES {
  STUDY = 'STUDY',
}
