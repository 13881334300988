import cornerstone from 'cornerstone-core';
import csTools from 'cornerstone-tools';

import { getDeepCopy } from '~/utils/tsUtils';

const moveHandle = csTools.importInternal('manipulators/moveHandle');
const drawTextBox = csTools.importInternal('drawing/drawTextBox');
const drawLink = csTools.importInternal('drawing/drawLink');

const { state } = csTools.store;

export const _moveHandleNearImagePoint = (evt, tool, toolData, handle, interactionType) => {
  toolData.active = true;
  state.isToolLocked = true;

  const doneHandler = () => {
    const { element } = evt.detail;
    const toolName = toolData.toolType || toolData.toolName;
    const modifiedEventData = {
      toolName,
      toolType: toolName,
      element,
      measurementData: { ...toolData, active: false },
    };

    cornerstone.triggerEvent(element, csTools.EVENTS.MEASUREMENT_COMPLETED, modifiedEventData);
  };

  moveHandle(evt.detail, tool.name, toolData, handle, tool.options, interactionType, doneHandler);

  evt.stopImmediatePropagation();
  evt.stopPropagation();
  evt.preventDefault();

  return;
};

export const _getFindingBoxTextCoords = (viewport, handles, index) => {
  const corners = _determineCorners(handles);
  const centerX = (corners.left.x + corners.right.x) / 2;
  const centerY = (corners.top.y + corners.bottom.y) / 2;
  const textBox = { x: 0, y: 0, index };

  if (viewport.rotation >= 0 && viewport.rotation < 90) {
    textBox.x = viewport.hflip ? corners.left.x : corners.right.x;
    textBox.y = centerY;
  }
  if (viewport.rotation >= 90 && viewport.rotation < 180) {
    textBox.x = centerX;
    textBox.y = viewport.vflip ? corners.bottom.y : corners.top.y;
  }
  if (viewport.rotation >= 180 && viewport.rotation < 270) {
    textBox.x = viewport.hflip ? corners.right.x : corners.left.x;
    textBox.y = centerY;
  }
  if (viewport.rotation >= 270 && viewport.rotation < 360) {
    textBox.x = centerX;
    textBox.y = viewport.vflip ? corners.top.y : corners.bottom.y;
  }

  return textBox;
};

const _determineCorners = (handles) => {
  const handlesLeftToRight = [handles.start, handles.end].sort((a, b) => (a.x < b.x ? -1 : 1));
  const handlesTopToBottom = [handles.start, handles.end].sort((a, b) => (a.y < b.y ? -1 : 1));
  const left = handlesLeftToRight[0];
  const right = handlesLeftToRight[handlesLeftToRight.length - 1];
  const top = handlesTopToBottom[0];
  const bottom = handlesTopToBottom[handlesTopToBottom.length - 1];

  return {
    top,
    left,
    bottom,
    right,
  };
};

export const _findTextBoxAnchorPoints = (startHandle, endHandle) => {
  const { left, top, width, height } = _getRectangleImageCoordinates(startHandle, endHandle);

  return [
    {
      // Top middle point of rectangle
      x: left + width / 2,
      y: top,
    },
    {
      // Left middle point of rectangle
      x: left,
      y: top + height / 2,
    },
    {
      // Bottom middle point of rectangle
      x: left + width / 2,
      y: top + height,
    },
    {
      // Right middle point of rectangle
      x: left + width,
      y: top + height / 2,
    },
  ];
};

export const _organizePositions = (positions: Array<{ x: number; y: number; index: number }>) => {
  const range = 100;
  const adjustedList = getDeepCopy(positions);
  const sorted = adjustedList.sort((a, b) => (a.index > b.index ? 1 : -1));

  for (let i = 0; i < sorted.length; i++) {
    for (let j = i + 1; j < sorted.length; j++) {
      const yDifference = Math.abs(sorted[i].y - sorted[j].y);
      if (yDifference > 80) continue;
      sorted[j].y = sorted[i].y + range;
    }
  }

  return adjustedList;
};

const _getRectangleImageCoordinates = (startHandle, endHandle) => {
  return {
    left: Math.min(startHandle.x, endHandle.x),
    top: Math.min(startHandle.y, endHandle.y),
    width: Math.abs(startHandle.x - endHandle.x),
    height: Math.abs(startHandle.y - endHandle.y),
  };
};

export const _drawFindingTextBox = (
  context,
  element,
  textBox,
  text,
  handles,
  textBoxAnchorPoints,
  color,
  lineWidth,
  xOffset,
  yCenter,
) => {
  const { pixelToCanvas } = cornerstone;

  // Convert the textbox Image coordinates into Canvas coordinates
  const textCoords = pixelToCanvas(element, textBox);

  if (xOffset) textCoords.x += xOffset;

  const options = {
    centering: { x: false, y: yCenter },
  };

  textBox.boundingBox = drawTextBox(context, text, textCoords.x, textCoords.y, color, options);
  const linkAnchorPoints = textBoxAnchorPoints(handles).map((h) => pixelToCanvas(element, h));
  drawLink(linkAnchorPoints, textCoords, textBox.boundingBox, context, color, lineWidth);
};
