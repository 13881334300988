import { SelectOption } from '@evacenter/eden';

export enum StudyFilterName {
  patientGender = 'patientGender',
  tagSpecialty = 'tagSpecialty',
  seriesModality = 'seriesModality',
  dicomDateTimeGte = 'dicomDateTimeGte',
  dicomDateTimeLte = 'dicomDateTimeLte',
  status = 'status',
  slaStatus = 'slaStatus',
  urgencyLevel = 'urgencyLevel',
  isSealed = 'isSealed',
  studyType = 'studyType',
  practitionersAssignedUserId = 'practitionersAssignedUserId',
  practitionerReviewerUserId = 'practitionerReviewerUserId',
  seriesDicomOperatorsName = 'seriesDicomOperatorsName',
  facility = 'facility',
  reportsBirads = 'reportsBirads',
  patientAgeStart = 'patientAgeStart',
  patientAgeEnd = 'patientAgeEnd',
  pacsStudyStartDate = 'pacsStudyStartDate',
  pacsStudyEndDate = 'pacsStudyEndDate',
  reportSignedStartDate = 'reportSignedStartDate',
  reportSignedEndDate = 'reportSignedEndDate',
  rightPossession = 'rightPossession',
  search = 'search',
  criticalValuesId = 'criticalValuesId',
}

export interface StudyFilterFields {
  isSealed: Array<SelectOption>;
  patientGender: Array<SelectOption>;
  tagSpecialty: Array<SelectOption>;
  seriesModality: Array<SelectOption>;
  dicomDateTimeGte: string;
  dicomDateTimeLte: string;
  status: Array<SelectOption>;
  slaStatus: Array<SelectOption>;
  urgencyLevel: Array<SelectOption>;
  studyType: Array<SelectOption>;
  practitionersAssignedUserId: Array<SelectOption>;
  practitionerReviewerUserId: Array<SelectOption>;
  seriesDicomOperatorsName: Array<SelectOption>;
  facility: Array<SelectOption>;
  reportsBirads: Array<SelectOption>;
  patientAgeStart: string;
  patientAgeEnd: string;
  pacsStudyStartDate: string;
  pacsStudyEndDate: string;
  reportSignedStartDate: string;
  reportSignedEndDate: string;
  rightPossession?: SelectOption;
  criticalValuesId?: Array<SelectOption>;
}
