export enum ToolName {
  Length = 'Length',
  Pan = 'Pan',
  Bidirectional = 'Bidirectional',
  CTR = 'CTR',
  Angle = 'Angle',
  CobbAngle = 'CobbAngle',
  EllipticalRoi = 'EllipticalRoi',
  RectangleRoi = 'RectangleRoi',
  RectangleCrop = 'RectangleCrop',
  Deviation = 'Deviation',
  FreehandRoi = 'FreehandRoi',
  Probe = 'Probe',
  SpineLabel = 'SpineLabel',
  SpineLabelReferences = 'SpineLabelReferences',
  ArrowAnnotate = 'ArrowAnnotate',
  Coxometry = 'Coxometry',
  Goniometry = 'Goniometry',
  NippleInjury = 'NippleInjury',
  HeatmapDrawer = 'HeatmapDrawer',
  Zoom = 'Zoom',
  Magnify = 'Magnify',
  Wwwc = 'Wwwc',
  Eraser = 'Eraser',
  Rotate = 'Rotate',
  FindingBox = 'FindingBox',
  MammographyReferenceLines = 'MammographyReferenceLines',
  ExternalReferenceLines = 'ExternalReferenceLines',
  Scroll = 'StackScroll',
}
