export const FRESH_CHAT_SCRIPT_ID = 'freshchat-lib';
export const FRESH_CHAT_HOST_URL = process.env.REACT_APP_FRESHCHAT_HOST_URL;
export const FRESH_CHAT_SCRIPT_URL = `${FRESH_CHAT_HOST_URL}/js/widget.js`;
export const FRESH_CHAT_WIDGET_CONTAINER_ID = 'fc_frame';
export const FRESH_CHAT_PREVIEW_CONTAINER_CLASSNAME = 'h-open-notify';

export enum ChatServiceStatus {
  NotLoaded = 'not_loaded',
  Loaded = 'loaded',
  Ready = 'ready',
}

export interface FreshWidgetIframeClasses {
  widget?: string;
  open?: string;
  expanded?: string;
}

export interface FreshWidgetChatConfig {
  disableEvents?: boolean;
  cssNames?: FreshWidgetIframeClasses;
  showFAQOnOpen?: boolean;
  hideFAQ?: boolean;
  agent?: {
    hideName?: boolean;
    hidePic?: boolean;
    hideBio?: boolean;
  };
  headerProperty?: {
    //If you have multiple sites you can use the appName and appLogo to overwrite the values.
    appName?: string;
    appLogo?: string;
    backgroundColor?: string;
    foregroundColor?: string;
    backgroundImage?: string;
    hideChatButton: boolean;
  };
  content?: {
    placeholders?: {
      search_field?: string;
      reply_field?: string;
      csat_reply?: string;
    };
    actions?: {
      csat_yes?: string;
      csat_no?: string;
      push_notify_yes?: string;
      push_notify_no?: string;
      tab_faq?: string;
      tab_chat?: string;
      csat_submit?: string;
    };
    headers?: {
      chat?: string;
      chat_help?: string;
      faq?: string;
      faq_help?: string;
      faq_not_available?: string;
      faq_search_not_available?: string;
      faq_useful?: string;
      faq_thankyou?: string;
      faq_message_us?: string;
      push_notification?: string;
      csat_question?: string;
      csat_yes_question?: string;
      csat_no_question?: string;
      csat_thankyou?: string;
      csat_rate_here?: string;
      channel_response?: {
        offline?: string;
        online?: {
          minutes?: {
            one?: string;
            more?: string;
          };
          hours?: {
            one?: string;
            more?: string;
          };
        };
      };
    };
  };
}

export interface FreshChatAPIResponse {
  status: number;
  data: any;
  success: boolean;
}
export interface FreshChatUserInfo {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  phoneCountryCode?: string;
  organization?: string;
  meta?: {
    user_id?: string;
    cf_organization_id?: string;
    cf_organizationname?: string;
    cf_practitioner_id?: string;
  };
}

export interface FreshChatConfig extends FreshChatUserInfo {
  token?: string;
  host?: string;
  externalId?: string; // user's id unique to your system
  siteId?: string;
  restoreId?: string | undefined;
  config?: FreshWidgetChatConfig;
}

export interface FreshChatUser {
  get: () => Promise<any>;
  logout: () => Promise<any>;
  setProperties: (metaProperties: { [key: string]: string }) => Promise<any>;
  update: (properties: FreshChatConfig) => Promise<any>;
}

export interface FreshChatWidget {
  init: (config: FreshChatConfig) => void;
  on: (event: string, callBack: any) => void;
  open: () => void;
  destroy: () => void;
  isInitialized: () => boolean;
  show: () => void;
  user?: FreshChatUser;
}
