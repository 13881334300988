/**
 * Clips a value to an upper and lower bound.
 * @export @public @method
 * @name clip
 *
 * @param  {number} val  The value to clip.
 * @param  {number} low  The lower bound.
 * @param  {number} high The upper bound.
 * @returns {number}      The clipped value.
 */
export const clip = (val: number, low: number, high: number) => {
  return Math.min(Math.max(low, val), high);
};

/**
 * Clips a value within a box.
 * @export @public @method
 * @name clipToBox
 *
 * @param  {Object} point The point to clip
 * @param  {Object} box   The bounding box to clip to.
 * @returns {Object}       The clipped point.
 */
export const clipToBox = (point, box) => {
  const newPoint = { ...point };
  // Clip an {x, y} point to a box of size {width, height}
  newPoint.x = clip(point.x, 0, box.width);
  newPoint.y = clip(point.y, 0, box.height);
  return newPoint;
};
