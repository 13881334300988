import { TranscriberV2 } from './transcriberv2';
var EdenApiService = /** @class */ (function () {
    function EdenApiService(sink) {
        this.transcriber = new TranscriberV2(sink);
    }
    EdenApiService.prototype.start = function () {
        this.transcriber.start();
    };
    EdenApiService.prototype.stop = function () {
        this.transcriber.stop();
    };
    EdenApiService.getInstance = function (sink) {
        if (!EdenApiService.instance) {
            EdenApiService.instance = new EdenApiService(sink);
        }
        return EdenApiService.instance;
    };
    EdenApiService.prototype.getTranscriber = function () {
        return this.transcriber;
    };
    EdenApiService.instance = null;
    return EdenApiService;
}());
var initEdenApiService = function (sink) {
    var service = EdenApiService.getInstance(sink);
    return service;
};
export { initEdenApiService, EdenApiService };
