import { useCreateUserEventLogMutation } from '@eva-pacs/client';
import { USER_EVENT_MODEL_TYPES, USER_EVENT_PAGE_LOCATION, USER_EVENT_TYPES } from '~/constants';

export const useUserEventLogs = () => {
  const [createUserEventLog] = useCreateUserEventLogMutation();

  const logUserEvent = (
    eventType: typeof USER_EVENT_TYPES[keyof typeof USER_EVENT_TYPES],
    objectId: string,
    location: typeof USER_EVENT_PAGE_LOCATION[keyof typeof USER_EVENT_PAGE_LOCATION],
  ) => {
    createUserEventLog({
      variables: {
        input: {
          eventType,
          modelType: USER_EVENT_MODEL_TYPES.STUDY,
          pageLocation: location,
          objectId,
          datetime: new Date().toISOString(),
        },
      },
    });
  };

  return {
    logUserEvent,
  };
};
