import { SerieItem, useSeriesStore } from '~/src/store';
import { FileItem } from '../thumbnails';
import { FileType, MODALITY_TYPES } from '@eva-pacs/core';

export const addStlSeries = (studyId: string, stlFiles: Array<FileItem>) => {
  stlFiles.forEach((stlFile) => {
    const dicomImageList = [
      {
        id: stlFile.fileUrl,
        externalId: stlFile.id,
      },
    ];
    const stlSerie: SerieItem = {
      id: (crypto as any).randomUUID(),
      isLoaded: true,
      description: stlFile.name,
      dicomImageList,
      imagesIds: dicomImageList.map((image) => image.id),
      numImageFrames: dicomImageList.length,
      hasDocument: false,
      modalityType: MODALITY_TYPES.OTHER,
      fileType: FileType.STL,
      dicomSeriesId: '',
      frameRate: 0,
      studyId,
    };
    useSeriesStore.getState().addNewSeries(stlSerie);
  });
};
