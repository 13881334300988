import { MeasurementType } from '@eva-pacs/client';

const isValueTrue = (value) => {
  if (typeof value === 'boolean') return value;
  if (typeof value === 'string') return value.toLowerCase().includes('true');
  return false;
};

const sanitizeIaMeasurements = (measurement): MeasurementType => {
  try {
    const isRectangle =
      measurement.tool?.toLowerCase() === 'rectangleroi' ||
      measurement.tool?.toLowerCase() === 'boundingbox' ||
      measurement.tool?.toLowerCase() === 'findingbox' ||
      measurement.tool?.toLowerCase() === 'ai';
    const isFinding = measurement.isAiGenerated && isRectangle;
    if (!isFinding) return measurement;
    const result = {
      ...measurement,
      // eslint-disable-next-line i18next/no-literal-string
      tool: 'FindingBox',
      data: {
        ...measurement.data,
        active: isValueTrue(measurement?.data?.active),
        complete: isValueTrue(measurement?.data?.complete),
        unit: '',
        invalidated: isValueTrue(measurement?.data?.invalidated),
        visible: true,
        cachedStats: {
          ...measurement?.data?.cachedStats,
          area: measurement?.data?.cachedStats?.area || 0,
          count: measurement?.data?.cachedStats?.count || 0,
          max: measurement?.data?.cachedStats?.max || 0,
          mean: measurement?.data?.cachedStats?.mean || 0,
          min: measurement?.data?.cachedStats?.min || 0,
          stdDev: measurement?.data?.cachedStats?.stdDev || 0,
          variance: measurement?.data?.cachedStats?.variance || 0,
        },
        handles: {
          ...measurement?.data?.handles,
          textBox: {
            ...measurement?.data?.handles,
            active: measurement?.data?.handles?.active || false,
            allowedOutsideImage: measurement?.data?.handles?.allowedOutsideImage || true,
            drawnIndependently: measurement?.data?.handles?.drawnIndependently || true,
            hasBoundingBox: true,
            hasMoved: false,
            movesIndependently: measurement?.data?.handles?.movesIndependently || false,
            moving: measurement?.data?.handles?.moving || false,
          },
        },
      },
    };
    return result;
  } catch {
    return measurement;
  }
};

const addMedicalConditionsCounter = (iAMeasurements: Array<MeasurementType>): Array<MeasurementType> => {
  const measurments = [...iAMeasurements];
  const findingCounter = new Map<string, number>();
  const conditionIndexer: Record<string, number> = {};

  //Count the number of findings for each medical condition
  measurments.forEach((measurement) => {
    if (!measurement.finding?.medicalCondition.name) return;
    findingCounter.set(
      measurement.finding?.medicalCondition.name || '',
      (findingCounter.get(measurement.finding?.medicalCondition.name) || 0) + 1,
    );
  });

  measurments.forEach((measurement) => {
    const finding = measurement.finding;
    if (!finding) return;
    const medicalCondition = finding.medicalCondition?.name;

    if (!conditionIndexer[medicalCondition]) {
      conditionIndexer[medicalCondition] = 1;
    } else {
      conditionIndexer[medicalCondition] += 1;
    }
    const count = findingCounter.get(medicalCondition) || 1;
    if (count > 1 && conditionIndexer[medicalCondition]) {
      const newFinding = { ...finding };
      // Add counter to the medical condition name if there are more than one to facilitate the distinction
      newFinding.medicalCondition = {
        ...finding.medicalCondition,
        name: `${finding.medicalCondition.name} (${conditionIndexer[medicalCondition]})`,
      };
      measurement.finding = newFinding;
    }
  });

  return measurments;
};

export const formatMeasurements = (measurements: Array<MeasurementType> = []) => {
  const formatedMeasurements = measurements
    .map((measurement) => {
      const data = {
        ...measurement,
        data: JSON.parse(measurement.data)[0],
      };
      const sanitized = sanitizeIaMeasurements(data);
      return sanitized;
    })
    .sort((measure) => (measure.isAiGenerated ? -1 : 1));
  let iAMeasurements = formatedMeasurements.filter((m) => m.isAiGenerated);
  iAMeasurements = addMedicalConditionsCounter(iAMeasurements);
  const commonMeasurements = formatedMeasurements.filter((m) => !m.isAiGenerated);
  return [...iAMeasurements, ...commonMeasurements];
};
