import cornerstone from 'cornerstone-core';

const HIDE_THRESHOLD = 0;
export const DEFAULT_HEATMAP_COLOR_MAP = 'jet';

// Pre-load the colormap once, assuming it won't change dynamically
const colorMap = cornerstone.colors.getColormap(DEFAULT_HEATMAP_COLOR_MAP, {});
const heatmapColorRange = colorMap.getNumberOfColors() - 1;

/**
 * Maps a given value (between 0 and 1) to a corresponding RGBA color from the 'jet' colormap.
 * @author Mauricio Campos <mau.cdr.19@gmail.com>
 * Created at 2025-02-27
 * @param value - The value to be mapped, expected to be a number between 0 and 1.
 * @returns A string representing the RGBA color value.
 */
export const getMappedColor = (value: number) => {
  const normalizedValue = Math.max(0, Math.min(1, value));
  const colorIndex = Math.round(heatmapColorRange * normalizedValue);
  const [r, g, b] = colorMap.getColor(colorIndex);
  const intensity = normalizedValue <= HIDE_THRESHOLD ? 0 : normalizedValue;
  return `rgba(${r},${g},${b},${intensity})`;
};
