import React from 'react';
import { useReportAndPatientFromStudyQuery } from '@eva-pacs/client';
import { useModal } from '@eva-pacs/core';
import { PreviewPDF, Spinner } from '@eva-pacs/ui';
import { redirectToProductURL } from '~/utils/redirectToProductURL';
import {
  CAN_PROA_SEND_REPORT,
  EVA_PRODUCT,
  FeatureFlags,
  POLICIES,
  USER_EVENT_PAGE_LOCATION,
  USER_EVENT_TYPES,
} from '~/constants';
import { useFeatureFlagsStore, useSessionStore } from '~/src/store';
import { useUserEventLogs } from '~/hooks';

export interface PreviewPDFWrapperProps {
  /**
   * Study id
   */
  studyId: string;

  /**
   * Specify an optional className to be added to the component
   */
  className?: string;
}

/**
 * Use this component as logic for send report from preview pdf
 * @author Ricardo Alfonso Aguirre Nava<ricardo.a.nava@gmail.com>
 * Created at 2021-09-20
 */
export const PreviewPDFWrapper: React.FC<PreviewPDFWrapperProps> = ({ studyId }) => {
  const { setCurrentModal } = useModal();
  const hasFlag = useFeatureFlagsStore((state) => state.hasFlag);
  const { logUserEvent } = useUserEventLogs();

  const { ...reportFromStudy } = useReportAndPatientFromStudyQuery({
    variables: {
      id: studyId,
    },
    fetchPolicy: 'no-cache',
  });

  const study = reportFromStudy.data?.study;
  const firstReport = study?.reports[0];
  const reportUrl = firstReport?.pdfUrl;
  const reportLetterheadUrl = firstReport?.pdfLetterheadUrl;
  const showPrintButton = firstReport?.isExternalPdf !== true;

  const user = useSessionStore((state) => state.user);
  const roleCanSendQr = POLICIES[CAN_PROA_SEND_REPORT](user);
  const isProaUserBlockedFromSendingQr = hasFlag(FeatureFlags.BlockSendPROA) ? !roleCanSendQr : false;

  const goToSendView = () => {
    setCurrentModal(null);
    redirectToProductURL(EVA_PRODUCT.PACS, `report-send/${study?.id}`);
  };

  if (reportFromStudy.loading) return <Spinner />;

  const handleOnDownloadPdf = () =>
    logUserEvent(USER_EVENT_TYPES.DOWNLOAD_REPORT, studyId, USER_EVENT_PAGE_LOCATION.PACS_PREVIEW_PDF_LOCATION);

  const handleOnDefaultPrint = () =>
    logUserEvent(USER_EVENT_TYPES.PRINT_DEFAULT_REPORT, studyId, USER_EVENT_PAGE_LOCATION.PACS_PREVIEW_PDF_LOCATION);

  const handleOnLetterheadPrint = () =>
    logUserEvent(USER_EVENT_TYPES.PRINT_LETTERHEAD_REPORT, studyId, USER_EVENT_PAGE_LOCATION.PACS_PREVIEW_PDF_LOCATION);

  return (
    <div>
      <PreviewPDF
        onDefaultPrint={handleOnDefaultPrint}
        onLetterheadPrint={handleOnLetterheadPrint}
        onDownloadPdf={handleOnDownloadPdf}
        pdfFile={reportUrl as string}
        onPrimaryAction={goToSendView}
        showPrintButton={showPrintButton}
        showSendButton={!isProaUserBlockedFromSendingQr}
        pdfLetterheadFile={reportLetterheadUrl as string}
      />
    </div>
  );
};
