import { useEffect, useState } from 'react';

import { DefaultListTabNames } from '~/src/types/StudyList';

import { PARAMS_KEYS, useSearchParams } from '../useSearchParams';

export const useStudyListTabs = () => {
  const [activeTab, setActiveTab] = useState<DefaultListTabNames>();
  const searchParams = useSearchParams();

  useEffect(() => {
    const currentSearchParam = searchParams.get(PARAMS_KEYS.TABS);
    if (!currentSearchParam) return;

    setActiveTab(currentSearchParam as DefaultListTabNames);
  }, [searchParams]);

  return {
    activeTab,
  };
};
