var transcriptionAPIURL = process.env.REACT_APP_TRANSCRIPTIONS_API;
var getWsUrl = function (props) {
    var _a, _b;
    var url = new URL("wss://".concat(transcriptionAPIURL, "/ws/listen/"));
    url.searchParams.append('token', (_a = props.token) !== null && _a !== void 0 ? _a : '');
    url.searchParams.append('study_id', props.studyId);
    url.searchParams.append('report_field', props.reportField);
    url.searchParams.append('organization_id', props.organizationId);
    url.searchParams.append('sample_rate', props.sampleRate.toString());
    url.searchParams.append('last_word', (_b = props.lastWord) !== null && _b !== void 0 ? _b : '');
    return url.toString();
};
var TranscriptionActions;
(function (TranscriptionActions) {
    TranscriptionActions["TRANSCRIPTION"] = "transcription";
    TranscriptionActions["CLOSE_SOCKET"] = "close_socket";
    TranscriptionActions["STOP_CONNECTION"] = "stop";
})(TranscriptionActions || (TranscriptionActions = {}));
var TranscriberV2 = /** @class */ (function () {
    function TranscriberV2(sink) {
        this.socket = null;
        this.micStream = null;
        this.micSampleRate = null;
        this.record = false;
        this.lastMessage = '';
        this.sink = sink;
    }
    TranscriberV2.prototype.stop = function () {
        var _a;
        (_a = this.micStream) === null || _a === void 0 ? void 0 : _a.stop();
        this.micStream = null;
        this.closeSocket();
    };
    TranscriberV2.prototype.updateLastWord = function (lastWord) {
        this.sink.lastWord = lastWord;
    };
    TranscriberV2.prototype.closeSocket = function () {
        var _this = this;
        var _a;
        // Check if the webSocket is opened already
        if (this.socket && ((_a = this.socket) === null || _a === void 0 ? void 0 : _a.readyState) === WebSocket.OPEN) {
            this.socket.send(JSON.stringify({ action: TranscriptionActions.STOP_CONNECTION }));
            setTimeout(function () {
                var _a;
                (_a = _this.socket) === null || _a === void 0 ? void 0 : _a.close();
            }, 1000);
        }
    };
    TranscriberV2.prototype.start = function () {
        var _this = this;
        var pcmNode;
        var source;
        var audioContext;
        try {
            navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then(function (stream) {
                audioContext = new window.AudioContext();
                var audio = audioContext.audioWorklet.addModule('/worklets/processor.js').then(function () {
                    return stream;
                });
                return audio;
            })
                .then(function (stream) {
                source = audioContext.createMediaStreamSource(stream);
                pcmNode = new AudioWorkletNode(audioContext, 'pcm-processor');
                var sampleRate = audioContext.sampleRate;
                _this.micSampleRate = sampleRate;
                pcmNode.port.onmessage = function (event) {
                    var _a;
                    var pcmData = new Int16Array(event.data);
                    if (((_a = _this.socket) === null || _a === void 0 ? void 0 : _a.readyState) === WebSocket.OPEN)
                        _this.socket.send(pcmData.buffer);
                };
                source.connect(pcmNode);
                // Set up WebSocket after audio setup is complete
                var wsUrl = getWsUrl({
                    token: _this.sink.sessionToken,
                    studyId: _this.sink.studyId || '',
                    reportField: _this.sink.reportField || '',
                    organizationId: _this.sink.organizationId || '',
                    sampleRate: sampleRate,
                    lastWord: _this.sink.lastWord,
                });
                _this.socket = new WebSocket(wsUrl);
                _this.socket.onopen = function () {
                    var _a, _b;
                    _this.lastMessage = '';
                    if (((_a = _this.socket) === null || _a === void 0 ? void 0 : _a.readyState) === WebSocket.OPEN) {
                        _this.sink.onSuccessfullConnection();
                        (_b = _this.socket) === null || _b === void 0 ? void 0 : _b.send(JSON.stringify({ sampleRate: _this.micSampleRate }));
                    }
                };
                _this.socket.onmessage = function (message) {
                    var received = JSON.parse(message.data);
                    if ((received === null || received === void 0 ? void 0 : received.action) === TranscriptionActions.TRANSCRIPTION) {
                        _this.sink.onTranscript(received === null || received === void 0 ? void 0 : received.data);
                        _this.lastMessage = received.data;
                    }
                    if ((received === null || received === void 0 ? void 0 : received.action) === TranscriptionActions.CLOSE_SOCKET) {
                        _this.stop();
                    }
                };
                _this.socket.onclose = function () {
                    pcmNode.disconnect(); // Disconnect pcmNode instead of processor
                    source.disconnect();
                    _this.sink.onTerminate(_this.lastMessage);
                };
                _this.socket.onerror = function (error) {
                    console.log({ event: 'onerror', error: error });
                };
            })
                .catch(function (error) {
                console.error({ error: error });
            });
        }
        catch (error) {
            console.error(error);
        }
    };
    return TranscriberV2;
}());
export { TranscriberV2 };
