import csTools from 'cornerstone-tools';

import { hideCornerstoneMeasure, updateAllImages } from '~/components/Viewer/MeasurementsBrowser/handleMeasurements';
import { useMeasurementStore } from '~/src/store';

const { eraserCursor } = csTools.importInternal('tools/cursors');
const BaseTool = csTools.importInternal('base/BaseTool');

/**
 * @public
 * @class EraserTool
 * @memberof Tools
 *
 * @classdesc Tool for deleting the data of other Annotation Tools.
 * @extends Tools.Base.BaseTool
 */
export default class EraserTool extends BaseTool {
  preMouseDownCallback: (evt: any) => boolean;
  preTouchStartCallback: (evt: any) => boolean;

  constructor(props = {}) {
    const defaultProps = {
      name: 'Eraser',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      svgCursor: eraserCursor,
    };

    super(props, defaultProps);

    this.preMouseDownCallback = this._deleteAllNearbyTools.bind(this);
    this.preTouchStartCallback = this._deleteAllNearbyTools.bind(this);
  }

  _deleteAllNearbyTools(evt) {
    const coords = evt.detail.currentPoints.canvas;
    const element = evt.detail.element;

    csTools.store.state.tools.forEach((tool) => {
      const toolState = csTools.getToolState(element, tool.name);
      if (!toolState) return;

      toolState.data.forEach((data) => {
        const isAiMeasure = Boolean(data?.automatic_bounding_box?.length);
        const isValidMeasure = typeof tool.pointNearTool === 'function' && tool.pointNearTool(element, data, coords);

        if (isValidMeasure && isAiMeasure) {
          useMeasurementStore.getState().hideMeasure(data.uuid);
          hideCornerstoneMeasure(data.uuid);
        }
        if (isValidMeasure && !isAiMeasure) csTools.removeToolState(element, tool.name, data);

        updateAllImages();
      });
    });

    const consumeEvent = true;

    return consumeEvent;
  }
}
