import { create, GetState, SetState } from 'zustand';
import produce from 'immer';

export const immer = (config) => (set, get) => config((fn) => set(produce(fn)), get);

/**
 * Flags Store
 */
export interface TranscriptionsStore {
  isIaraEnabled: boolean;
  setIsIaraEnabled: (isIaraEnabled: boolean) => void;
  getIsIaraEnabled: () => boolean;
}

const transcriptionsStore = (
  set: SetState<TranscriptionsStore>,
  get: GetState<TranscriptionsStore>,
): TranscriptionsStore => ({
  isIaraEnabled: false,
  getIsIaraEnabled: () => get().isIaraEnabled,
  setIsIaraEnabled: (isEnabled: boolean) => {
    return set((state) => {
      state.isIaraEnabled = isEnabled;
      return state;
    });
  },
});

export const useTranscriptionsStore = create<TranscriptionsStore>(immer(transcriptionsStore));
