import React from 'react';
import { pdfjs } from 'react-pdf';
import ReactDom from 'react-dom';
import { ToastProvider, ToastPlacementV2, ToastContainerV2 } from '@evacenter/eden';
import { ApolloProvider } from '@apollo/client/react';
import { apolloClient, authRepository } from '@eva-pacs/client';
import { ModalProvider } from '@eva-pacs/core';
import { ModalManager } from '@eva-pacs/ui';
import { FreshChatWidget } from '~/src/constants';
import { CoordinateType } from '~/utils/vtk/vtkGrabWidgetTools';
import initCornerstone from '~/utils/initCornerstone';
import reportWebVitals from './reportWebVitals';
import App from './App';

/**
 * Initialize i18n
 */
import '@eva-pacs/i18n';

/**
 * Initialize tailwind css and custom css files
 */
import '@eva-pacs/ui-next/lib/tailwind.css';
import '@evacenter/eden/dist/bundle.css';
import '@eva-pacs/ui/lib/tailwind.css';
import { AuthContextProvider } from './contexts/authContext';

/**
 * Initialize cornerstone
 */
initCornerstone();

/**
 * Enable PDF.js worker
 */
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// overwrite window
declare global {
  interface Window {
    cornerstone: any;
    cornerstoneWADOImageLoader: any;
    cornerstoneTools: any;
    auxMeasure: Array<CoordinateType>;
    fcWidget: FreshChatWidget;
    syncedApp: Window | null;
  }
}

const bootstrapApp = async () => {
  ReactDom.render(
    <>
      <ApolloProvider client={apolloClient}>
        <AuthContextProvider authRepository={authRepository}>
          <ToastProvider>
            <ToastContainerV2 placement={ToastPlacementV2.bottomEnd} />
            <ModalProvider>
              <>
                <ModalManager />
                <App />
              </>
            </ModalProvider>
          </ToastProvider>
        </AuthContextProvider>
      </ApolloProvider>
    </>,
    document.getElementById('root'),
  );
};

bootstrapApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
