import React, { useEffect } from 'react';

import { DownloadModalContent, DownloadModalContentType } from '@eva-tech/viewers-react-shared';

import { DownloadStatus, useCreateDownloadMutation, CreateDownloadMutation } from '@eva-pacs/client';
import { handleProgrammaticDownload, useInterval } from '@eva-pacs/core';
import { RETRY_DOWNLOAD_TIME } from '~/constants';
import { useErrorHandler } from '~/utils/appHelpers';

export interface DownloadDicomModalProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Pass study id for mutation
   */
  studyId: string;

  /**
   * Handle close current modal
   */
  onClose: () => void;
}

/**
 * Use this component in order to show waiting state for zip compression and download modal
 * @author Ricardo Aguirre Nava<ricardo.a.nava@gmail.com>
 * Created at 2021-09-06
 */
export const DownloadDicomModal: React.FC<DownloadDicomModalProps> = ({ studyId, onClose }) => {
  const { handleError } = useErrorHandler();

  const [createDownloadMutation] = useCreateDownloadMutation({
    onCompleted: ({ createDownload: { success, download, errors } }: CreateDownloadMutation) => {
      if (success && download?.status === DownloadStatus.COMPLETED) {
        onClose();
        handleProgrammaticDownload(download.fileUrl as string);
      } else if (!success && errors) {
        onClose();
        handleError({
          logMessage: Object.keys(errors)[0],
          modalProps: { onClose },
        });
      }
    },
    onError: (error) => {
      handleError({
        logMessage: error?.message,
      });
    },
  });

  useEffect(() => {
    createDownloadMutation({
      variables: { input: { study: studyId } },
    });
  }, [createDownloadMutation, studyId]);

  useInterval(() => {
    createDownloadMutation({
      variables: { input: { study: studyId } },
    });
  }, RETRY_DOWNLOAD_TIME);

  return <DownloadModalContent modalType={DownloadModalContentType.zip} onClose={onClose} />;
};
