var IARA_ENGINE = 'Iara Desktop';
var IaraHealthService = /** @class */ (function () {
    function IaraHealthService(sink) {
        var _this = this;
        this.lastMessage = '';
        this.init = function () {
            var _a, _b;
            IaraHealthService.instance = _this;
            (_b = (_a = _this.sink).onInitStart) === null || _b === void 0 ? void 0 : _b.call(_a);
            _this.recognition
                .init({
                userId: _this.sink.userEmail,
                lang: 'pt-BR',
                apiToken: _this.sink.apiTokenIara,
                forceConnection: true,
                engine: IARA_ENGINE,
                interimResults: true,
                useVAD: true,
            })
                .done(function () {
                var _a, _b;
                (_b = (_a = _this.sink).onFinishInit) === null || _b === void 0 ? void 0 : _b.call(_a);
                _this.recognition.start();
            })
                .fail(function (e) {
                _this.sink.onError(e.detail.userMessage);
                console.error('There was an error ' + e.detail.userMessage);
            })
                .progress(function (e) {
                // Este método es invocado múltiples veces por el SDK a lo largo de su inicialización.
                // Se puede utilizar para llevar la cuenta de los pasos de inicialización, para informar
                // su usuario que su modelo de voz se está descargando, por ejemplo.
                console.log('Iara is initializing: ' + e.detail.type);
            });
            _this.recognition.onresult = function (event) {
                var text = event.detail.transcript;
                var richTranscript = event.detail.richTranscript;
                _this.sink.onTranscript(richTranscript);
                _this.lastMessage = text;
            };
            _this.recognition.addEventListener(window.IaraEvent.SPEECH_RECOGNITION_VAD_VOICE_START, function () {
                var _a, _b;
                (_b = (_a = _this.sink).onSpeechRecognitionVadStart) === null || _b === void 0 ? void 0 : _b.call(_a);
            });
            _this.recognition.addEventListener(window.IaraEvent.SPEECH_RECOGNITION_VAD_VOICE_STOP, function () {
                var _a, _b;
                (_b = (_a = _this.sink).onSpeechRecognitionVadStop) === null || _b === void 0 ? void 0 : _b.call(_a);
            });
            _this.recognition.addEventListener(window.IaraEvent.OGG_FILE_EXPORTED, function (event) {
                // TODO[Chava]: integrate this audio with transcriptions api: https://eva-pacs.atlassian.net/browse/EVA-6902
                var audioBlob = event.detail.audioBlob;
                console.log({ audioBlob: audioBlob });
            });
            _this.recognition.addEventListener(window.IaraEvent.ALS_OFFLINE, function (event) {
                var _a, _b;
                console.log({ event: event });
                (_b = (_a = _this.sink).onALSOffline) === null || _b === void 0 ? void 0 : _b.call(_a);
            });
            _this.recognition.addEventListener(window.IaraEvent.ALS_ONLINE, function (event) {
                var _a, _b;
                console.log({ event: event });
                (_b = (_a = _this.sink).onALSOnline) === null || _b === void 0 ? void 0 : _b.call(_a);
            });
            _this.recognition.addEventListener(window.IaraEvent.INIT_INSTALL_ALS, function (event) {
                var _a, _b;
                (_b = (_a = _this.sink).onInitInstallError) === null || _b === void 0 ? void 0 : _b.call(_a, event.detail.userMessage);
            });
            _this.recognition.addEventListener(window.IaraEvent.INIT_FAIL, function (event) {
                _this.sink.onError(event.detail.userMessage);
            });
            _this.recognition.addEventListener(window.IaraEvent.ERROR, function (event) {
                _this.sink.onError(event.detail.userMessage);
                console.error('There was an error ' + event.detail.userMessage);
            });
        };
        this.sink = sink;
        if (!window.IaraSpeechRecognition) {
            throw new Error('IARA Health transcriber speech recognition is not available.');
        }
        this.recognition = new window.IaraSpeechRecognition();
    }
    IaraHealthService.prototype.start = function () {
        this.sink.onSuccessfullConnection();
        this.recognition.start();
    };
    IaraHealthService.prototype.stop = function () {
        this.recognition.stop();
        this.sink.onTerminate(this.lastMessage);
    };
    IaraHealthService.getInstance = function () {
        return IaraHealthService.instance;
    };
    IaraHealthService.instance = null;
    IaraHealthService.clearInstance = function () {
        IaraHealthService.instance = null;
    };
    IaraHealthService.initService = function (sink) {
        IaraHealthService.instance = new IaraHealthService(sink);
        var service = IaraHealthService.getInstance();
        if (service) {
            service.init();
        }
        else {
            throw new Error('IaraHealthService instance is not available');
        }
    };
    return IaraHealthService;
}());
var initIaraHealthService = function (sink) {
    IaraHealthService.initService(sink);
    var service = IaraHealthService.getInstance();
    return service;
};
export { initIaraHealthService, IaraHealthService };
