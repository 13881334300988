/**
 * Combines multiple heatmaps by first normalizing each heatmap and then summing their values.
 * @param heatmaps - An array of heatmaps to be combined. Each heatmap is a 2D array of numbers.
 * @returns A new 2D array representing the combined heatmap with normalized values.
 */
export const combineHeatmaps = (...heatmaps: Array<Array<Array<number>>>): Array<Array<number>> => {
  if (!heatmaps?.length) return [];

  const height = heatmaps[0].length;
  const width = heatmaps[0][0].length;

  // Initialize the result array with zeros
  const result: Array<Array<number>> = Array.from({ length: height }, () => new Array(width).fill(0));

  // Normalize each heatmap and store the results
  const normalizedHeatmaps = heatmaps.map((heatmap) => normalizeHeatmap(heatmap));

  // Combine the normalized heatmaps by adding corresponding values
  normalizedHeatmaps.forEach((heatmap) => {
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        result[y][x] += heatmap[y][x];
      }
    }
  });

  return result; // Return the combined heatmap
};

/**
 * Normalizes a given heatmap by scaling its values to the range [0, 1].
 * @param heatmap - The heatmap to be normalized, represented as a 2D array of numbers.
 * @returns A new 2D array representing the normalized heatmap.
 */
function normalizeHeatmap(heatmap: Array<Array<number>>): Array<Array<number>> {
  const height = heatmap.length;
  const width = heatmap[0].length;

  let maxVal = 0;

  // Find the maximum value in the heatmap
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      maxVal = Math.max(maxVal, heatmap[y][x]);
    }
  }

  // Normalize the heatmap values by dividing each value by the maximum value
  if (maxVal > 0) {
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        heatmap[y][x] /= maxVal;
      }
    }
  }

  return heatmap;
}
