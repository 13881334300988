import produce from 'immer';
import { create, GetState, SetState } from 'zustand';
export const immer = (config) => (set, get) => config((fn) => set(produce(fn)), get);

interface HeatmapConfig {
  opacity: number;
  visible: boolean;
}
/**
 * Store for tools global configuration
 */
interface ToolStoreConfig {
  /**
   * List of product roles by user
   */
  heatmapConfig: HeatmapConfig;
  /**
   * Action to set Heatmap config
   */
  setHeatmapConfig: (config: Partial<HeatmapConfig>) => void;
  /**
   * Get Heatmap config
   */
  getHeatmapConfig: () => HeatmapConfig;
}

const toolsStoreConfig = (set: SetState<ToolStoreConfig>, get: GetState<ToolStoreConfig>): ToolStoreConfig => ({
  heatmapConfig: {
    opacity: 0.6,
    visible: false,
  },
  setHeatmapConfig: (config) =>
    set((state) => {
      state.heatmapConfig = { ...get().heatmapConfig, ...config };
      return state;
    }),
  getHeatmapConfig: () => get().heatmapConfig,
});

export const useToolStoreConfig = create<ToolStoreConfig>(immer(toolsStoreConfig));
